export interface DataBag {
    dates: string[];
    titles: string[];
    locations: string[];
    authors: string[];
    categories: string[];
}

class Extractors {
    static dates = archiveEntry => {
        return Extractors.format(archiveEntry.date);
    }

    static titles = archiveEntry => {
        return Extractors.format(archiveEntry.title);
    }

    static authors = archiveEntry => {
        return Extractors.format(archiveEntry.author);
    }

    static locations = archiveEntry => {
        return Extractors.format(archiveEntry.location);
    }

    static categories = archiveEntry => {
        return Extractors.format(archiveEntry.category);
    }

    private static format(str: string) {
        return str.toLowerCase().trim();
    }
}

export class DataExtractor {
    constructor(private data) {
    }

    public extract(): DataBag {
        return {
            dates: this.extractField(Extractors.dates),
            titles: this.extractField(Extractors.titles),
            authors: this.extractField(Extractors.authors),
            categories: this.extractField(Extractors.categories),
            locations: this.extractField(Extractors.locations),
        };
    }

    private extractField(extractFunc: (archiveEntry: any) => string): string[] {
        const fields: string[] = [];
        for (const archiveEntry of Object.keys(this.data)) {
            for (const element of this.data[archiveEntry]) {
                const field = extractFunc(element);
                if (fields.lastIndexOf(field) < 0) {
                    fields.push(field);
                }
            }
        }
        return fields;
    }

}
