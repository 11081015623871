/* tslint:disable:no-trailing-whitespace */
import {Component, Injectable, OnInit} from '@angular/core';
import {ApplicationStateService} from '../../services/application-state-service.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent {

    constructor(private state: ApplicationStateService) {
    }

    public mobile = this.state.state;


}
