export class ImageInstance {

    private readonly _id: string;
    private readonly _img;

    constructor(id: string, img) {
        this._id = id;
        this._img = img;
    }


    get id(): string {
        return this._id;
    }


    get img() {
        return this._img;
    }
}
