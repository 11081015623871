import {Component} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AppRepository, FormattedData} from './DataSource';


@Component({
    selector: 'app-vodi',
    templateUrl: './vodi.component.html',
    styleUrls: ['./vodi.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class VodiComponent {

    constructor(private repository: AppRepository) {

    }

    dataSource = this.repository;
    columnsToDisplay = ['Skupina', 'Starost', 'Termin', 'Vodnik'];

    columnsToDisplayIcon = ['Skupina', 'Starost', 'Termin', 'Vodnik', 'icon'];
    expandedElement: FormattedData | null;

    mail(address: string) {
        window.location.href = 'mailto:' + address;
    }

}
