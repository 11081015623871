export interface Index {
    entries: {
        id: string;
        entry: Entry;
    }[];
}

export interface Entry {
    title: string;
    date: string;
    location: string;
    author: string;
    category: string;
}


export class ArchiveIndex {

    private _index: Index = {entries: []};

    public build(data: any) {
        for (const archiveEntry of Object.keys(data)) {
            for (const element of data[archiveEntry]) {
                this._index.entries.push({
                        id: element.id,
                        entry: {
                            title: element.title,
                            date: element.date,
                            location: element.location,
                            author: element.author,
                            category: element.category
                        }
                    }
                );
            }
        }
    }

    public clear() {
        this._index = {entries: []};
    }


    get index(): Index {
        return this._index;
    }
}
