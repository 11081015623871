/* tslint:disable:no-trailing-whitespace */

export enum GestureDirection {
    left, right
}

interface GestureSnapshot {
    x?: number;
    y?: number;
    timestamp?: number;
}

export class Gestures {

    private surface;

    private start: GestureSnapshot;
    private end: GestureSnapshot;

    public allowedGestureTime = 500;
    public threshold = 70;
    public restraint = 150;

    private static timestamp(): number {
        return new Date().getTime();
    }

    private static createSnapshot(event: TouchEvent): GestureSnapshot {
        const touch = event.changedTouches[0];
        return {
            x: touch.pageX,
            y: touch.pageY,
            timestamp: Gestures.timestamp()
        };
    }

    onSwipe: (GestureDirection) => void = () => {
    }

    constructor(surface: EventTarget = window) {
        this.surface = surface;
        this.setListeners();
    }

    private setListeners() {
        this.surface.addEventListener('touchstart', (event) => {
            this.touchStart(event);
        }, {passive: false});
        this.surface.addEventListener('touchend', (event) => {
            this.touchEnd(event);
        }, {passive: false});
    }

    private touchStart(event: TouchEvent) {
        this.start = Gestures.createSnapshot(event);
    }

    private touchEnd(event: TouchEvent) {
        this.end = Gestures.createSnapshot(event);
        this.recognizeGesture();

    }

    private recognizeGesture() {
        if (this.elapsedTime() > this.allowedGestureTime) {
            return;
        }

        const horizontalDistance = this.horizontalDistance();
        const verticalDistance = this.verticalDistance();

        if (Math.abs(horizontalDistance) >= this.threshold && Math.abs(verticalDistance) <= this.restraint) {
            const direction = (horizontalDistance < 0) ? GestureDirection.left : GestureDirection.right;
            this.onSwipe(direction);
        }
    }

    private horizontalDistance(): number {
        return this.start.x - this.end.x;
    }

    private verticalDistance(): number {
        return this.start.y - this.end.y;
    }

    private elapsedTime(): number {
        return this.end.timestamp - this.start.timestamp;
    }
}
