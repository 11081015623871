import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-tax',
    templateUrl: './tax.component.html',
    styleUrls: ['./tax.component.css']
})
export class TaxComponent {

}
