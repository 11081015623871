/* tslint:disable:no-trailing-whitespace variable-name */
import {Injectable} from '@angular/core';
import {ImageCollection} from '../model/ImageCollection';

export enum TransitionType {
    Gallery,
    Left,
    Right,
    None
}

export interface Transition {
    fromImage: HTMLImageElement;
    TransitionType: TransitionType;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor() {
    }

    private _transition: Transition;
    private imageCollection: ImageCollection;

    nextImage(current: string): string {
        const initialized = typeof this.imageCollection !== 'undefined';
        return initialized ? this.imageCollection.next(current) : '';
    }

    previousImage(current: string): string {
        const initialized = typeof this.imageCollection !== 'undefined';
        return initialized ? this.imageCollection.previous(current) : null;
    }

    initImageCollection(collection: ImageCollection) {
        this.imageCollection = collection;
    }

    get transition() {
        const value = this._transition;
        this._transition = null;
        return value;
    }

    set transition(value) {
        this._transition = value;
    }
}
