/* tslint:disable:no-trailing-whitespace */
import {ImageProperties} from '../../image-grid/ImageProperties';

export class ImageCollection {

    private readonly json;
    private readonly albumId: string;

    public orderByDate = true;

    constructor(json, albumId: string) {
        this.json = json;
        this.albumId = albumId;
        this.setImages();
    }

    // tslint:disable-next-line:variable-name
    private _images = new Array<ImageProperties>();
    private unorderedImages: Array<ImageProperties>;

    get images(): ImageProperties[] {
        if (!this.orderByDate) {
            return this.unorderedImages;
        } else {
            return this._images;
        }
    }

    private setImages() {
        for (const image of this.json) {
            this._images.push(
                new ImageProperties(
                    image.id,
                    this.albumId,
                    image.metadata.dateTaken
                )
            );
        }
        this.sortByDate();
    }

    private sortByDate() {
        this.unorderedImages = [...this._images];
        this._images.sort((a, b) => {
            return a.date - b.date;
        });
    }

    public size() {
        return this._images.length;
    }

    public getImage(index: number) {
        if (this.images.length > index) {
            return this.images[index];
        } else {
            throw new Error('Index out of bounds');
        }
    }

    public removeImage(id: string) {
        for (let i = 0; i < this._images.length; i++) {
            if (this._images[i].id === id) {
                this._images.splice(i, 1);
            }
            if (this.unorderedImages[i].id === id) {
                this.unorderedImages.splice(i, 1);
            }
        }
    }

    public next(current: string): string {
        const currentIndex = this.findIndexOf(current);
        if (currentIndex < 0 || currentIndex + 1 === this.images.length) {
            return '';
        }
        const next = this.images[currentIndex + 1];
        return next.id;
    }

    public previous(current: string): string {
        const currentIndex = this.findIndexOf(current);
        if (currentIndex < 0 || currentIndex === 0) {
            return '';
        }
        const next = this.images[currentIndex - 1];
        return next.id;    }

    private findIndexOf(id: string): number {
        let index = 0;
        for (const image of this.images) {
            if (image.id === id) {
                return index;
            }
            index++;
        }
        return -1;
    }
}
