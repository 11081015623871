
<div class="wrapper" [ngClass]="{'shadow' : showTitleInAppBar}">
  <button routerLink=".." mat-icon-button aria-label="Example icon button with a vertical three dot icon">
    <mat-icon>arrow_back</mat-icon>
  </button>

  <span [ngClass]="{'visible' : showTitleInAppBar}" class="title" *ngIf="album">
      {{album.getTitle()}}
    </span>

  <div class="options">
    <button *ngIf="editor" mat-icon-button (click)="openEditor()">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button (click)="openShareDialog()">
      <mat-icon>share</mat-icon>
    </button>
  </div>
</div>

