/* tslint:disable:no-trailing-whitespace no-string-literal variable-name */

import {ImageRow} from './ImageRow';

export class ImageGrid {
    private readonly _rows: Array<ImageRow>;

    constructor(rows: Array<ImageRow>) {
        this._rows = rows;
    }

    get rows(): Array<ImageRow> {
        return this._rows;
    }

    public addRow(imageRow: ImageRow) {
        this._rows.push(imageRow);
    }

    public addRowAt(imageRow: ImageRow, index: number) {
        this._rows.splice(index, 0, imageRow);
    }

    public clear() {
        this._rows.length = 0;
    }

    public clearFrom(rowIndex: number) {
        if (rowIndex < this._rows.length) {
            this._rows.length = rowIndex;
        }
    }

}
