<app-appbar></app-appbar>

<div class="wrapper">

  <h1 class="title mat-display-3 unselectable">Seznam vodov</h1>

  <div class="overflow">
    <table class="table" mat-table [dataSource]="dataSource" multiTemplateDataRows>

      <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
        <th mat-header-cell *matHeaderCellDef> {{ column }}</th>
        <td mat-cell *matCellDef="let element"> {{ element[column] }}</td>
      </ng-container>

      <ng-container matColumnDef="icon">
        <td class="more_cell" mat-cell *matCellDef="let element">
          <mat-icon class="unselectable" *ngIf="element != expandedElement" aria-hidden="false"
                    aria-label="more icon">expand_more
          </mat-icon>
          <mat-icon class="unselectable" *ngIf="element == expandedElement" aria-hidden="false"
                    aria-label="less icon">expand_less
          </mat-icon>
        </td>
      </ng-container>


      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail"
               [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="contact-wrap">
              <h2>{{ element["Vod"] }}</h2>
              <div class="details">
                <div class="basic">
                  <h3><span class="contact">Startost:</span> {{ element["Starost"] }}</h3>
                  <h3><span class="contact">Termin:</span> {{ element["Termin"] }}</h3>
                  <h3><span class="contact">Vodnik:</span> {{ element["Vodnik"] }}</h3>
                </div>
                <div style="width: 50px;"></div>
                <div class="more">
                  <h3><span class="contact">Kontakt:</span>&nbsp;<a
                    href="mailto:{{element.Mail}}">{{ element.Mail }}</a></h3>
                  <h3><span class="contact">Lokacija:</span> klub/Mariborski taborniški center</h3>
                  <h3><span class="contact">Vod:</span> {{ element.Vod }}</h3>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplayIcon;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>

    <p style="margin-top: 24px">Cena programa na udeleženca na mesec - 4,17 EUR (letna članarina znaša 50 EUR)</p>

  </div>

  <div class="maps">
      <div>
        <p>Klub</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2743.7132886432087!2d15.645569900000002!3d46.5533582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476f77a6e0a2b221%3A0x8cdbdee58c57106!2sVerstov%C5%A1kova%20ulica%204%2C%202000%20Maribor!5e0!3m2!1sen!2ssi!4v1718704715089!5m2!1sen!2ssi"
          width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"
          referrerpolicy="no-referrer-when-downgrade" id="location-klub"></iframe>
      </div>
    <div>
      <p>Mariborki taborniški center</p>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d342.88217635220315!2d15.645599!3d46.56633!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476f77ad31c779c1%3A0x229206ba4989f4b7!2sTrubarjeva%20ulica%2024%2C%202000%20Maribor!5e0!3m2!1sen!2ssi!4v1718704742124!5m2!1sen!2ssi"
      width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"
      referrerpolicy="no-referrer-when-downgrade" id="location-mtc"></iframe>
    </div>
  </div>

</div>
<app-footer></app-footer>
