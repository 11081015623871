<app-appbar></app-appbar>

<div class="wrapper">

  <h1 class="title mat-display-3">Informacije</h1>

  <h1 class="mat-headline unselectable">Tedenska srečanja</h1>
  <h3 class="mat-h3">Vsak vod (skupina otrok iste starosti) se srečuje z vodnikom na tedenskih srečanjih. Ta običajno
    trajajo eno uro in se odvijejo v popoldnevih delavnikov. Termin v začetku šolskega leta določi vodnik ob posvetu
    s starši. Trenutne termine lahko preveriš tukaj: <a routerLink="/vodi">Seznam vodov</a>.
  </h3>
  <h1 class="mat-headline unselectable">Kontaktne informacije</h1>
  <h3>
    Vsak vodnik ima svojo telefonsko številko in e-naslov, ki ju najdete na <a routerLink="/vodi">seznamu vodov</a>. V
    primeru vprašanj nam lahko pišeš preko spletnega formularja ali na uradni naslov društva: <a
    href="mailto:xisnoub@gmail.com">xisnoub@gmail.com</a>. Za nujne informacije pa kontaktiraj načelnika rodu Mitjo na:
    <a href="tel:030631075">030 631 075</a>, <a href="mailto:brglez.mitja@gmail.com">brglez.mitja@gmail.com</a>, ali
    starešino rodu Nino na: <a href="tel:040626264">040 626 264</a>, <a href="mailto:ninamedved.nm@gmail.com">ninamedved.nm@gmail.com</a>.
    V primeru vprašanj nam lahko pišeš preko spletnega formularja ali na uradni naslov društva: xisnoub@gmail.com. Več
    najdeš na strani: <a routerLink="/kdo-smo">Kdo smo?</a> .
  </h3>
  <h1 class="mat-headline unselectable">Postani član</h1>
  <h3 class="mat-h3">Informacije o vpisu in poskusnem obisku vodovega srečanja najdeš na strani <a
    routerLink="/pridruzi-se">Vpis</a>.
    S članstvom v našem taborniškem
    rodu boš postal tudi član nacionalne skavtske organizacije – <a href="https://www.taborniki.si/">Zveze
      Tabornikov Slovenije</a>. Članstvo prinaša številne ugodnosti, med drugim mesečni izvod <a
      href="https://www.taborniki.si/projekti/revija-tabor/">revije Tabor</a>.
  </h3>
  <h1 class="mat-headline unselectable">Članarina</h1>
  <h3 class="mat-h3">Član našega društva in Zveze Tabornikov Slovenije postaneš ob plačilu letne članarine (50€).
    Članstvo moraš obnoviti pred začetkom naslednjega koledarskega leta. Več informacij in natančno obrazložitev,
    kaj vse zajema članarina, najdeš na strani <a routerLink="/pridruzi-se">Vpis</a>.
  </h3>
  <h1 class="mat-headline unselectable">Letno taborjenje</h1>
  <h3 class="mat-h3">
    Taborjenje je vrhunec taborniškega leta. Mladim ponuja priložnost da iskusijo življenje v naravi pod platneno
    streho. Čeprav je za mnoge to prva daljša odsotnos od doma, Čeprav je to za mnoge prva daljša odsotnost od doma,
    mine teden in pol kot bi mahnil. Dnevi so polni dogodivščin, druženja, zabave in avantur. V varnem in zdravem
    okolju gradimo občutek skupnosti, nova prijateljstva in mlade pripravljamo na odgovorno odraslost. Ne
    primanjkuje skupinskih iger, raziskovanja narave, pohodništva, nočnih straž, postavljanja objektov iz naravnih
    materialov, učenja veščin preživetja v naravi, udejstvovanja v lokalnem okolju, kulturno-zabavnih večerov ob
    spremljavi kitare in še bi lahko naštevali.
  </h3>
  <h1 class="mat-headline unselectable">Mesečne akcije</h1>
  <h3 class="mat-h3">
    Vsak mesec priredimo tudi krajšo taborniško akcijo. Posebno se veselimo Jesenovanja in Pomladovanja, ko
    preživimo vikend na eni izmed planinskih koč – pravo malo taborjenje! Decembra nas obišče Dedek Mraz, aprila se
    odpravimo v Ljubljano na Taborniški Feštival, februarja se drasamo in sankamo in junija pokažemo pridobljeno
    taborniško znanje na celoslovenskem tekmovanju. Seveda se posamezni vodi odpravljajo tudi na priložnostne
    akcije, tekmovanja in izlete. Dogodivščinam ni konca!
  </h3>


</div>
<app-footer></app-footer>
