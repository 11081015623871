/* tslint:disable:no-trailing-whitespace */
// noinspection JSMethodCanBeStatic

import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Config} from '../../../../../global/Config';
import {NavigationService, Transition, TransitionType} from '../navigation.service';
import {AnimationGenerator} from '../AnimationGenerator';
import {ShareDialogComponent} from '../../share-dialog/share-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ArchiveService} from '../../../archive.service';
import {DownloadDialogComponent} from './image-download-dialog/download-dialog.component';
import {Meta} from '@angular/platform-browser';
import {Array} from '../../../../../global/Array';
import {GestureDirection, Gestures} from './Gestures';

@Component({
    selector: 'app-photo',
    templateUrl: './photo-view.component.html',
    styleUrls: ['./photo-view.component.css']
})
export class PhotoViewComponent implements OnInit, OnDestroy {

    constructor(private route: ActivatedRoute,
                private router: Router,
                private navigationService: NavigationService,
                public dialog: MatDialog,
                private archiveService: ArchiveService,
                private metaService: Meta
    ) {
        this.handleRouteChanges();
        this.setTabColor();
    }

    imageId;
    url = '';
    backgroundClasses: string[] = [];
    toolbarClasses: string[] = [];

    readonly imageStyles: any[] = [{}, {}];
    readonly imageCLasses: string[][] = [[], []];
    currentImageHolder = 0;

    navigationVisible = false;
    inFullscreen = false;
    private previousImage: HTMLImageElement = new Image();
    private nextImage: HTMLImageElement = new Image();
    private currentImageCache: HTMLImageElement = new Image();

    @HostBinding('style.--target-width')
    private targetWidth = '0px';
    @HostBinding('style.--target-height')
    private targetHeight = '0px';
    @HostBinding('style.--target-offset-left')
    private targetOffsetLeft = '0px';
    @HostBinding('style.--target-offset-top')
    private targetOffsetTop = '0px';
    @HostBinding('style.--last-target-width')
    private lastTargetWidth = '0px';
    @HostBinding('style.--last-target-height')
    private lastTargetHeight = '0px';
    @HostBinding('style.--last-target-offset-left')
    private lastTargetOffsetLeft = '0px';
    @HostBinding('style.--last-target-offset-top')
    private lastTargetOffsetTop = '0px';

    ngOnInit(): void {
        this.keyboardListener();
        this.windowSizeListener();
        this.cursorMovementListener();
        this.gestures();
    }

    ngOnDestroy(): void {
        this.clearKeyboardListener();
        this.removeTabColor();
    }

    private handleRouteChanges() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const newId = this.getImageId();
                if (newId !== this.imageId) {
                    this.url = this.buildUrl(true);
                    this.imageId = newId;
                    this.setImage();
                }
            }
        });
    }

    private setTabColor() {
        this.metaService.updateTag({
            name: 'theme-color',
            content: '#000000'
        });
        this.metaService.updateTag({
            name: 'apple-mobile-web-app-status-bar-style',
            content: 'black'
        });
    }

    private removeTabColor() {
        this.metaService.removeTag('name=\'theme-color\'');
        this.metaService.removeTag('name=\'apple-mobile-web-app-status-bar-style\'');
    }

    private setImage() {
        const image = this.getCurrentImageHolder();
        const transition = this.navigationService.transition;
        if (this.transitionExists(transition)) {
            this.loadImageWithTransition(transition);
        } else {
            this.loadPlainImage(image);
        }
    }

    private loadImageWithTransition(transition: Transition) {
        if (transition.TransitionType === TransitionType.Gallery) {
            this.galleryTransition(transition);
        } else {
            this.imageTransition(transition);
        }
    }

    private galleryTransition(transition: Transition) {

        const nextImage = this.getCurrentImageHolder();

        nextImage.onload = () => {
            if (nextImage.src === this.buildUrl(true)) {
                this.computeImageSizeAndPosition(nextImage);
                this.transition(transition);
                nextImage.src = this.buildUrl();
                this.imageCLasses[this.currentImageHolder].push('visible');
            } else {
                this.preloadCloseImages();
            }
        };
        nextImage.src = this.url;
    }

    private imageTransition(transition: Transition) {
        this.clearImageStyles();
        const previousImageHolder = this.currentImageHolder;
        this.currentImageHolder = this.imageCLasses.length - 1 - this.currentImageHolder;
        const nextImage = this.getCurrentImageHolder();

        if (this.nextImage.src === this.buildUrl(false) && this.nextImage.complete) {
            if (this.nextImage.src !== nextImage.src) {
                nextImage.src = this.nextImage.src;
            }
            const startTransition = () => {
                this.computeImageSizeAndPosition(nextImage);
                this.transition(transition);
                for (const imageClass of this.imageCLasses) {
                    imageClass.push('fullscreen');
                }
                this.imageCLasses[this.currentImageHolder].push('visible');
                Array.remove(this.imageCLasses[previousImageHolder], 'visible');
                this.preloadCloseImages();
            };
            const loaded = nextImage.complete && nextImage.naturalHeight !== 0;
            if (loaded) {
                startTransition();
            } else {
                nextImage.onload = startTransition;
            }
        } else {
            nextImage.onload = () => {
                if (nextImage.src === this.buildUrl(true)) {
                    this.computeImageSizeAndPosition(nextImage);
                    this.transition(transition);
                    nextImage.src = this.buildUrl();
                    for (const imageClass of this.imageCLasses) {
                        imageClass.push('fullscreen');
                    }
                    this.imageCLasses[this.currentImageHolder].push('visible');
                    Array.remove(this.imageCLasses[previousImageHolder], 'visible');
                } else {
                    this.preloadCloseImages();
                }
            };
            nextImage.src = this.url;
        }
    }

    private clearImageStyles() {
        for (const key of this.imageStyles.keys()) {
            this.imageStyles[key] = {};
        }
    }

    private loadPlainImage(image: HTMLImageElement) {
        this.backgroundClasses.push('black-background');
        image.onload = () => {
            if (image.src === this.buildUrl(true)) {
                this.computeImageSizeAndPosition(image);
                image.src = this.buildUrl();
                this.imageCLasses[this.currentImageHolder].push('visible');
                for (const imageClass of this.imageCLasses) {
                    imageClass.push('fullscreen');
                }
            } else {
                this.preloadCloseImages();
                image.onload = () => {
                };
            }
        };
        image.src = this.url;
    }

    private computeImageSizeAndPosition(image: HTMLImageElement) {

        this.lastTargetWidth = this.targetWidth;
        this.lastTargetHeight = this.targetHeight;
        this.lastTargetOffsetLeft = this.targetOffsetLeft;
        this.lastTargetOffsetTop = this.targetOffsetTop;

        let height = window.innerHeight;
        let factor = height / image.naturalHeight;
        let width = image.naturalWidth * factor;

        if (width > window.innerWidth) {
            width = window.innerWidth;
            factor = width / image.naturalWidth;
            height = image.naturalHeight * factor;
        }

        this.targetHeight = height + 'px';
        this.targetWidth = width + 'px';

        const left = (window.innerWidth - width) / 2;
        const top = (window.innerHeight - height) / 2;

        this.targetOffsetLeft = left + 'px';
        this.targetOffsetTop = top + 'px';


    }

    private transition(transition: Transition) {
        const animationGenerator = new AnimationGenerator();
        switch (transition.TransitionType) {
            case TransitionType.Gallery:
                this.imageStyles[0] = animationGenerator.galleryTransition(transition.fromImage);
                this.backgroundClasses.push('fadeInBackground');
                break;
            case TransitionType.Right:
                this.imageStyles[this.currentImageHolder] = animationGenerator.nextTransition(this.getNextImageHolder());
                this.imageStyles[this.getNextImageHolderIndex()] = animationGenerator.fadeOutTransitionNext();
                break;
            case TransitionType.Left:
                this.imageStyles[this.currentImageHolder] = animationGenerator.previousTransition(this.getNextImageHolder());
                this.imageStyles[this.getNextImageHolderIndex()] = animationGenerator.fadeOutTransitionPrevious();
                break;
        }
    }

    private transitionExists(transition: Transition): boolean {
        return transition !== undefined &&
            transition !== null;
    }

    toggleFullscreen() {
        if (document.fullscreenElement === null) {
            document.body.requestFullscreen().then(() => {
            });
        } else {
            document.exitFullscreen().then(() => {
            });
        }

    }

    next() {
        const nextid = this.navigationService.nextImage(this.imageId);
        if (nextid === '') {
            return;
        }
        this.navigationService.transition = {
            TransitionType: TransitionType.Right,
            fromImage: undefined
        };
        this.router.navigate([`../${nextid}`], {relativeTo: this.route, replaceUrl: true});
    }

    previous() {
        const previousId = this.navigationService.previousImage(this.imageId);
        if (previousId === '') {
            return;
        }
        this.navigationService.transition = {
            TransitionType: TransitionType.Left,
            fromImage: undefined
        };
        this.router.navigate([`../${previousId}`], {relativeTo: this.route, replaceUrl: true});
    }

    exit() {
        this.removeTabColor();
        this.router.navigate(['..'], {relativeTo: this.route, replaceUrl: true});
    }

    private keyboardListener() {
        document.onkeydown = (e) => {
            if (e.code === 'ArrowLeft') {
                e.preventDefault();
                this.previous();
            } else if (e.code === 'ArrowRight') {
                e.preventDefault();
                this.next();
            } else if (e.code === 'KeyF') {
                this.toggleFullscreen();
            } else if (e.code === 'Escape') {
                e.preventDefault();
                this.exit();
            }
        };
    }

    private windowSizeListener() {
        const image = this.getCurrentImageHolder();
        window.onresize = () => {
            this.computeImageSizeAndPosition(image);
        };
        this.updateFullscreenState();
        document.onfullscreenchange = () => {
            this.updateFullscreenState();
        };
    }

    private updateFullscreenState() {
        this.inFullscreen = document.fullscreenElement !== null;
    }

    private clearKeyboardListener() {
        document.onkeydown = () => {
        };
    }

    private cursorMovementListener() {
        const onTimeout = () => {
            this.hideAppBar();
            this.navigationVisible = false;
        };

        let timeout = setTimeout(onTimeout, 3000);
        const onInterruption = () => {
            const isAppbarVisible = this.toolbarClasses.indexOf('visible') > 0;
            if (!isAppbarVisible) {
                this.toolbarClasses.push('visible');
            }
            if (!this.navigationVisible) {
                this.navigationVisible = true;
            }
            clearTimeout(timeout);
            timeout = setTimeout(onTimeout, 3000);
        };

        window.onmousemove = onInterruption;
        window.onmousedown = onInterruption;
        onInterruption();
    }

    private hideAppBar() {
        for (let index = this.toolbarClasses.indexOf('visible'); !(index < 0); index = this.toolbarClasses.indexOf('visible')) {
            this.toolbarClasses.splice(index, 1);
        }
    }

    private gestures() {
        const gestures = new Gestures();
        gestures.onSwipe = (direction) => {
            switch (direction) {
                case GestureDirection.left:
                    this.previous();
                    break;
                case GestureDirection.right:
                    this.next();
            }
        };
    }

    private preloadCloseImages() {
        const nextid = this.navigationService.nextImage(this.imageId);
        const previousId = this.navigationService.previousImage(this.imageId);
        const previousUrl = this.buildUrl(false, previousId);
        if (this.previousImage.src === previousUrl) {
            return;
        }
        this.preloadImage(this.nextImage, nextid);
        this.preloadImage(this.currentImageCache, this.imageId);
        this.preloadImage(this.previousImage, previousId);

    }

    private preloadImage(image: HTMLImageElement, id) {
        if (id) {
            image.src = this.buildUrl(false, id);
        }
    }

    openShareDialog(): void {
        this.clearKeyboardListener();
        const dialog = this.dialog.open(ShareDialogComponent, {
            width: '450px',
            data: {title: 'Deli fotografijo', album: this.getAlbumId(), image: this.getImageId()},
            panelClass: 'basic-dialog-container',
            autoFocus: false
        });
        dialog.afterClosed().subscribe(() => {
            this.keyboardListener();
        });
    }

    downloadOriginal() {
        this.clearKeyboardListener();
        const dialog = this.dialog.open(DownloadDialogComponent, {
            width: '450px',
            data: {album: this.getAlbumId(), image: this.getImageId()},
            panelClass: 'basic-dialog-container',
        });
        dialog.afterClosed().subscribe(() => {
            this.keyboardListener();
        });
    }

    download(): void {
        this.archiveService.downloadBigImage(this.getAlbumId(), this.getImageId());
    }

    private getCurrentImageHolder(): HTMLImageElement {
        return this.getImageHolder(this.currentImageHolder);
    }

    private getNextImageHolder(): HTMLImageElement {

        return this.getImageHolder(this.getNextImageHolderIndex());
    }

    private getNextImageHolderIndex(): number {
        return this.imageCLasses.length - 1 - this.currentImageHolder;
    }

    private getImageHolder(index: number): HTMLImageElement {
        return document.getElementsByClassName('image')[index] as HTMLImageElement;
    }

    private buildUrl(
        small: boolean = false,
        imageId: string = this.getImageId(),
        albumId: string = this.getAlbumId()
    ): string {
        const size = small ? '?size=small' : '';
        return `${Config.API_IMAGE}${albumId}/${imageId}${size}`;
    }

    private getImageId(): string {
        return this.route.pathFromRoot[2].snapshot.paramMap.get('image');
    }

    private getAlbumId(): string {
        return this.route.pathFromRoot[1].snapshot.paramMap.get('album');
    }
}
