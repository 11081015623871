<div class="c-footer">
  <div class="wrapper">
    <div class="sponsors">
      <img id="MOM" src="assets/slike/PNG/MOM-Logotip-5.png">
      <p>
        Naše programe sofinancira <span class="sponsor-name">Mestna občina Maribor</span>. Iskrena hvala tudi našim
        zvestim donatorjem <span class="sponsor-name">Sava pokojninska družba</span>, <span class="sponsor-name">Dravske elektrarne Maribor</span>
        in <span class="sponsor-name">Mikro+Polo</span>.
      </p>
    </div>
    <div class="social-inner">
      <h1 class="mat-headline">Sledite nam na:</h1>
      <a href="https://instagram.com/xi.snoub/" target="_blank" aria-label="Instagram" data-g-category="footer"
         data-g-action="follow us" data-g-label="Instagram" data-g-href="https://instagram.com/google/">
        <svg class="" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
             viewBox="0 0 24 24">
          <path fill="var(--md-sys-color-on-surface)"
                d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z">
          </path>
        </svg>
      </a>
      <a href="https://www.youtube.com/channel/UCLsrKdalYfBcXewv7xhVIOg" target="_blank" aria-label="YouTube"
         data-g-category="footer" data-g-action="follow us" data-g-label="YouTube"
         data-g-href="https://www.youtube.com/user/Google">
        <svg class="" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
             viewBox="0 0 24 24" fill="#000">
          <path
            d="M23.495 6.205a3.007 3.007 0 0 0-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 0 0 .527 6.205a31.247 31.247 0 0 0-.522 5.805 31.247 31.247 0 0 0 .522 5.783 3.007 3.007 0 0 0 2.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 0 0 2.088-2.088 31.247 31.247 0 0 0 .5-5.783 31.247 31.247 0 0 0-.5-5.805zM9.609 15.601V8.408l6.264 3.602z">
          </path>
        </svg>
      </a>
      <a href="https://www.facebook.com/xisnoub" target="_blank" aria-label="Facebook" data-g-category="footer"
         data-g-action="follow us" data-g-label="Facebook" data-g-href="https://www.facebook.com/Google">
        <svg class="" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
             viewBox="0 0 24 24" fill="#000">
          <path
            d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0">
          </path>
        </svg>
      </a>
    </div>
    <div class="references desktop">
      <div class="column">
        <h1 class="mat-headline">Strani</h1>
        <ul>
          <li>
            <a class="" routerLink="/" routerLinkActive="active">Domov</a>
          </li>
          <li>
            <a class="" routerLink="/kdo-smo" routerLinkActive="active">Kdo smo</a>
          </li>
          <li>
            <a class="" routerLink="/koledar" routerLinkActive="active">Koledar</a>
          </li>
          <li>
            <a class="" routerLink="/vodi" routerLinkActive="active">Vodi</a>
          </li>
          <li>
            <a class="" routerLink="/pridruzi-se" routerLinkActive="active">Pridruži se</a>
          </li>
          <li>
            <a class="" routerLink="/povezave" routerLinkActive="active">Uporabne povezave</a>
          </li>
        </ul>
      </div>
      <div class="column">
        <h1 class="mat-headline">Povezane strani</h1>
        <ul>
          <li>
            <a class="" href="https://www.facebook.com/pg/xisnoub/photos/?tab=albums&ref=page_internal"
               target="_blank">Fotografije</a>
          </li>
          <li>
            <a class="" href="http://zot.taborniki.si/" target="_blank">Zimsko orientacijsko tekmovanje</a>
          </li>
          <li>
            <a class="" href="https://www.taborniki.si/" target="_blank">Zveza tabornikov Slovenije</a>
          </li>
          <li>
            <a class="" href="https://www.scout.org/" target="_blank">Svetovna skavtska organizacija</a>
          </li>
        </ul>
      </div>
      <div class="column">
        <h1 class="mat-headline">Formalno</h1>
        <ul>
          <li>
            <a class="" routerLink="/informacije" routerLinkActive="active">Informacije</a>
          </li>
          <li>
            <a class="" routerLink="/uniforma" routerLinkActive="active">Uniforma & rodova kolekcija</a>
          </li>
          <li>
            <a class="" href="https://drive.google.com/drive/folders/1QajNNOggeVbbSGYHLUwvr8hvWoDJEo_0"
               target="_blank">CGP</a>
          </li>
          <li>
            <a class="" routerLink="/taborni-prostor" routerLinkActive="active">Taborni prostor</a>
          </li>

          <li>
            <a class="" routerLink="/pravno-obvestilo" routerLinkActive="active">Pogoji
              uporabe</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="references mobile">
      <mat-accordion style="width: 100%;">
        <mat-expansion-panel class="expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>Strani</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="column">
            <ul>
              <li>
                <a class="" routerLink="/" routerLinkActive="active">Domov</a>
              </li>
              <li>
                <a class="" routerLink="/kdo-smo" routerLinkActive="active">Kdo smo</a>
              </li>
              <li>
                <a class="" routerLink="/koledar" routerLinkActive="active">Koledar</a>
              </li>
              <li>
                <a class="" routerLink="/vodi" routerLinkActive="active">Vodi</a>
              </li>
              <li>
                <a class="" routerLink="/pridruzi-se" routerLinkActive="active">Pridruži se</a>
              </li>
              <li>
                <a class="" routerLink="/povezave" routerLinkActive="active">Uporabne povezave</a>
              </li>
            </ul>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>Povezane strani</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="column">
            <ul>
              <li>
                <a class=""
                   href="https://www.facebook.com/pg/xisnoub/photos/?tab=albums&ref=page_internal"
                   target="_blank">Fotografije</a>
              </li>
              <li>
                <a class="" href="http://zot.taborniki.si/" target="_blank">Zimsko orientacijsko
                  tekmovanje</a>
              </li>
              <li>
                <a class="" href="https://www.taborniki.si/" target="_blank">Zveza tabornikov
                  Slovenije</a>
              </li>
              <li>
                <a class="" href="https://www.scout.org/" target="_blank">Svetovna skavtska
                  organizacija</a>
              </li>

            </ul>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="expansion-panel">
          <mat-expansion-panel-header>
            <mat-panel-title>Formalno</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="column">
            <ul>
              <li>
                <a class="" routerLink="/informacije" routerLinkActive="active">Informacije</a>
              </li>
              <li>
                <a class="" routerLink="/uniforma" routerLinkActive="active">Uniforma & rodova
                  kolekcija</a>
              </li>
              <li>
                <a class=""
                   href="https://drive.google.com/drive/folders/1QajNNOggeVbbSGYHLUwvr8hvWoDJEo_0"
                   target="_blank">CGP</a>
              </li>
              <li>
                <a class="" routerLink="/taborni-prostor" routerLinkActive="active">Taborni prostor</a>
              </li>
              <li>
                <a class="" routerLink="/pravno-obvestilo" routerLinkActive="active">Pogoji
                  uporabe</a>
              </li>
            </ul>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="foot">
      <svg id="logo" viewBox="0 0 1000 1000" width="40"
           height="40">
        <g id="g839">
          <g id="g825">
            <path class="st0"
                  d="M861.29,62.09c42.84,0,77.69,34.85,77.69,77.69v520.11h-77.1c-42.84,0-77.69-34.85-77.69-77.69V62.09H861.29     M861.29,26.06H748.16v556.13c0,62.8,50.91,113.71,113.71,113.71H975V139.77C975,76.97,924.09,26.06,861.29,26.06L861.29,26.06z"
                  id="path817"/>
            <path class="st0"
                  d="M205.52,436.28c-8.51-11.19-16.48-22.82-23.87-34.85L26.53,695.74l294.31-155.12    c-12.03-7.39-23.66-15.35-34.85-23.87C255.66,493.67,228.59,466.6,205.52,436.28z"
                  id="path819"/>
            <path class="st0"
                  d="M516.97,285.82c8.51,11.19,16.47,22.83,23.86,34.87L695.95,26.37L401.72,181.45    c12.03,7.39,23.65,15.36,34.83,23.88C466.86,228.41,493.91,255.49,516.97,285.82z"
                  id="path821"/>
            <path class="st0"
                  d="M512.76,348.27c-4.66-8.85-9.72-17.44-15.12-25.78c-25.36-39.11-58.67-72.45-97.77-97.82    c-8.39-5.44-17.04-10.53-25.93-15.22l-12.66-6.67L26.55,26.35l176.4,334.67l6.76,12.83c4.66,8.85,9.72,17.44,15.12,25.78    c25.36,39.11,58.67,72.45,97.77,97.82c8.39,5.44,17.04,10.53,25.93,15.22l12.66,6.67l334.72,176.42l-176.4-334.67L512.76,348.27z     M289.56,344.98h54.72l16.91-52.04l16.91,52.04h54.72l-44.27,32.17l16.91,52.04l-44.27-32.17l-44.27,32.17l16.91-52.04    L289.56,344.98z M115.86,115.65l206.81,109c-39.11,25.36-72.45,58.67-97.82,97.77L115.86,115.65z M606.62,606.46l-206.81-109    c39.11-25.36,72.45-58.67,97.82-97.77L606.62,606.46z"
                  id="path823"/>
          </g>
          <g id="g837">
            <path class="st0"
                  d="M25,943.01l21.02-26.12c15.61,14.11,33.03,24.92,55.25,24.92c20.12,0,33.03-11.11,33.03-26.72v-0.3    c0-13.21-6.9-21.92-40.23-34.23c-39.93-15.01-60.65-29.73-60.65-63.35v-0.6c0-34.53,27.62-58.85,66.66-58.85    c24.32,0,46.84,7.8,66.05,24.62L146.3,809.1c-14.41-11.71-30.33-19.82-47.14-19.82c-18.91,0-30.62,10.51-30.62,24.32v0.3    c0,14.71,7.8,22.52,43.53,36.33c39.03,15.01,57.05,31.23,57.05,61.85v0.6c0,36.93-28.82,60.65-68.16,60.65    C73.94,973.34,47.82,964.03,25,943.01z"
                  id="path827"/>
            <path class="st0"
                  d="M204.84,760.46h32.43l92.48,140.22V760.46h33.93v210.17h-29.42l-95.18-144.42v144.42h-34.23V760.46z"
                  id="path829"/>
            <path class="st0"
                  d="M401.5,867.35v-3c0-62.45,38.43-107.19,93.38-107.19c54.65,0,92.78,44.44,92.78,106.59v3    c0,62.45-38.43,107.19-93.38,107.19C439.63,973.94,401.5,929.5,401.5,867.35z M551.62,866.75v-2.1    c0-45.04-24.02-75.36-57.35-75.36c-33.03,0-56.75,29.73-56.75,75.06v2.1c0,45.04,24.32,75.36,57.35,75.36    C527.9,941.81,551.62,912.09,551.62,866.75z"
                  id="path831"/>
            <path class="st0"
                  d="M622.77,890.47V760.46h34.83v129.71c0,33.93,16.51,51.64,43.53,51.64c26.42,0,43.24-17.11,43.24-50.44V760.46    h34.53v128.81c0,56.45-30.93,84.67-78.37,84.67C653.7,973.94,622.77,945.42,622.77,890.47z"
                  id="path833"/>
            <path class="st0"
                  d="M822.13,760.46h75.06c22.82,0,42.03,6.3,53.75,18.01c9.31,9.31,14.11,21.02,14.11,35.43v0.9    c0,25.82-15.01,39.33-30.92,47.14c23.12,8.11,39.93,21.92,39.93,50.14v1.2c0,36.93-30.02,57.35-74.76,57.35h-77.17V760.46z     M930.22,819.61c0-17.71-12.91-27.92-35.43-27.92h-38.43v58.25h36.33c22.82,0,37.53-10.21,37.53-29.72V819.61z M898.69,879.36    h-42.33v60.05h43.84c24.02,0,39.03-10.51,39.03-29.73v-0.6C939.23,890.77,925.11,879.36,898.69,879.36z"
                  id="path835"/>
          </g>
        </g>
      </svg>
      <span> XI. SNOUB &copy; {{ year }}</span>
      <a routerLink="/kontakt">Kontakt</a>
      <div class="mobile-linebreak" style="flex-basis: 100%; margin-top: 10px; display: none;"></div>
      <span>Spletna stran: Izidor Golčar</span>
      <a class="login" routerLink="/prijava">Prijava</a>
    </div>
  </div>
</div>
