/* tslint:disable */
import {Injectable} from '@angular/core';
import {ArchiveManagementService} from './archive-management.service';
import {Observable, throwError} from 'rxjs';
import {catchError, last, map, tap} from "rxjs/operators";
import {HttpErrorResponse, HttpEvent, HttpEventType} from "@angular/common/http";

interface UploadState {
    currentlyUploading: string;
    queueLength: number;
    active: boolean;
    currentUploadProgress: number
}

@Injectable({
    providedIn: 'root'
})
export class FileTransferService {


    constructor(private uploadService: ArchiveManagementService) {

    }

    private readonly _fileQueue: File[] = [];

    private onFilesAddedCallback: (() => void) = () => {
    };

    private readonly _uploadQueue: File[] = [];
    private readonly _uploadState: UploadState = {
        active: false,
        currentUploadProgress: 0,
        currentlyUploading: "",
        queueLength: 0
    };
    private activeUploads = 0;

    get uploadState(): UploadState {
        return this._uploadState;
    }

    get fileQueue(): File[] {
        return this._fileQueue;
    }

    public pushAllToFileQueue(files: File[]) {
        for (const file of files) {
            this._fileQueue.push(file);
        }
        this.onFilesAddedCallback()
    }

    public onFilesAdded(onChange: () => void) {
        this.onFilesAddedCallback = onChange
    }

    public upload(
        albumId: string,
        addWatermarks: boolean,
        onImageUpload: () => void = () => {
        },
    ) {
        this.transferToUploadQueue();
        const uploadNext = () => {
            if (this._uploadQueue.length > 0) {
                const image = this._uploadQueue.pop();
                this.activeUploads++;
                const request = this.uploadService.uploadImage(image, albumId, addWatermarks);
                request.then(observable => {
                    observable.pipe(
                        catchError(e => {
                            this._uploadState.currentlyUploading = '';
                            this._uploadState.active = this._uploadQueue.length > 0;
                            this._uploadState.queueLength = this._uploadQueue.length;
                            this.activeUploads--;
                            uploadNext();
                            return new Observable();
                        })
                    ).subscribe((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                this.uploadState.active = true;
                                this._uploadState.currentlyUploading = image.name;
                                this._uploadState.queueLength = this._uploadQueue.length;
                                this._uploadState.currentUploadProgress = 0;
                                break;
                            case HttpEventType.UploadProgress:
                                const percentDone = Math.round(100 * event.loaded / event.total);
                                this._uploadState.currentUploadProgress = percentDone;
                                break;
                            case HttpEventType.Response:
                                this._uploadState.currentlyUploading = '';
                                this._uploadState.active = this._uploadQueue.length > 0;
                                this._uploadState.queueLength = this._uploadQueue.length;
                                this.activeUploads--;
                                uploadNext();
                                onImageUpload();
                                break
                        }
                    });
                });
            }
        }
        if (this.activeUploads == 0)
            uploadNext();
    }

    private transferToUploadQueue() {
        while (this.fileQueue.length > 0) {
            this._uploadQueue.push(this.fileQueue.pop());
        }
    }
}
