import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {CalendarComponent} from './pages/calendar/calendar.component';
import {ContactComponent} from './pages/kontakt/contact.component';
import {AboutPageComponent} from './pages/about-page/about-page.component';
import {JoinUsPageComponent} from './pages/join-us-page/join-us-page.component';
import {VodiComponent} from './pages/vodi/vodi.component';
import {ReferencesComponent} from './pages/references/references.component';
import {TermsOfUseComponent} from './pages/terms-of-use/terms-of-use.component';
import {InformationComponent} from './pages/informations/information.component';
import {TaborComponent} from './pages/tabor/tabor.component';
import {KrojComponent, OrderDialog} from './pages/kroj/kroj.component';
import {RaznoComponent} from './pages/razno/razno.component';
import {AppComponent} from '../../app-component/app.component';
import {HeaderComponent} from './pages/home/components/header/header.component';
import {AppbarComponent} from './appbar/appbar.component';
import {TabsComponent} from './pages/home/components/tabs/tabs.component';
import {FooterComponent} from './pages/home/components/footer/footer.component';
import {AboutComponent} from './pages/home/components/about/about.component';
import {TaxFormComponent, TaxFormDialog} from './pages/home/components/tax-form/tax-form.component';
import {ContactFormComponent} from './pages/kontakt/contact-form/contact-form.component';
import {ContactFormDirective} from './pages/kontakt/contact-form/contact-form.directive';
import {JoinUsComponent} from './pages/home/components/join-us/join-us.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSliderModule} from '@angular/material/slider';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {MatInputModule} from '@angular/material/input';
import {AdminModule} from './pages/admin/admin.module';
import {AuthModule} from './pages/auth/auth.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {ApplicationStateService} from './services/application-state-service.service';
import {MainRoutingModule} from './main-routing.module';
import {TaxComponent} from './pages/tax/tax.component';
import { TransparencyComponent } from './pages/transparency/transparency.component';

const config = {
    apiKey: 'AIzaSyDQQkGb_tLVCK4H4AXJktA1U9sVOsiU_h4',
    authDomain: 'enajsta.firebaseapp.com',
    databaseURL: 'https://enajsta.firebaseio.com',
    projectId: 'enajsta',
    storageBucket: 'enajsta.appspot.com',
    messagingSenderId: '276581524805',
    appId: '1:276581524805:web:b2e9f41e16fdcdfeec4a2c',
    measurementId: 'G-ZGZJ8JN2DX'
};

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        AppbarComponent,
        TabsComponent,
        FooterComponent,
        JoinUsPageComponent,
        AboutComponent,
        TaxFormComponent,
        HomeComponent,
        CalendarComponent,
        ContactComponent,
        JoinUsPageComponent,
        AboutPageComponent,
        VodiComponent,
        TaxFormDialog,
        ReferencesComponent,
        TermsOfUseComponent,
        InformationComponent,
        TaborComponent,
        KrojComponent,
        OrderDialog,
        RaznoComponent,
        ContactFormComponent,
        ContactFormDirective,
        JoinUsComponent,
        TaxComponent,
        TransparencyComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatSliderModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatRippleModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        AngularFireModule.initializeApp(config),
        AngularFireAuthModule,
        MatInputModule,
        AdminModule,
        AuthModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatProgressBarModule,
        AngularFireAnalyticsModule,
        MatMenuModule,
        MatExpansionModule,
        RecaptchaV3Module,
        MainRoutingModule
    ],
    providers: [ApplicationStateService,
        {provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Ld2UwcaAAAAAMe4u4Rh-woHPAbSW7a_Mwp56w2e'},
    ],
    exports: [RouterModule, FooterComponent]
})
export class MainModule {
}
