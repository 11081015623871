/* tslint:disable:no-trailing-whitespace */
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FileTransferService} from '../file-transfer.service';
import {ArchiveService} from '../../archive.service';
import {Album} from '../../photos/model/Album';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {GridBuilder} from '../../image-grid/GridBuilder';
import {observable, Subject} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {ArchiveManagementService} from '../archive-management.service';
import {ImageCollection} from '../../photos/model/ImageCollection';
import {MatSnackBar} from '@angular/material/snack-bar';

interface Editable {
    title: string;
    description: string;
    updatedTitle: string;
    updatedDescription;
}

@Component({
    selector: 'app-edit-album',
    templateUrl: './edit-album.component.html',
    styleUrls: ['./edit-album.component.css']
})
export class EditAlbumComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private router: Router,
                public fileTransferService: FileTransferService,
                private archiveService: ArchiveService,
                private archiveManagementService: ArchiveManagementService,
                public dialog: MatDialog,
                private snackBar: MatSnackBar
    ) {

        // TODO check if album exists
        this.id = route.snapshot.paramMap.get('id');

        this.initAlbumData();

        fileTransferService.onFilesAdded(() => {
            this.addImages();
        });
    }

    id: string = null;
    album: Album;
    addWatermarks = true;
    contentWidth = 0;
    imageMargins = window.innerWidth <= 1000 ? 1 : 3;
    horizontalPadding = true;
    loaded = false;
    selectedImages: string[] = [];
    gridBuilder = new GridBuilder();
    showTitleInAppBar = false;

    private updates = new Subject<void>();
    editableData: Editable;

    addImagesFromExplorer(event) {
        const files: File[] = event.target.files;
        this.fileTransferService.pushAllToFileQueue(files);
    }

    ngOnInit(): void {
        this.editableUpdates();
        this.screenSizeListener();
        this.scrollListener();
        this.keyboardListener();
    }

    select(id: string) {
        if (this.selectedImages.includes(id)) {
            this.selectedImages.splice(this.selectedImages.indexOf(id), 1);
        } else {
            this.selectedImages.push(id);
        }
    }

    openConfirmationDialog() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '250px',
            data: {count: this.selectedImages.length}
        });

        dialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
                this.deleteSelectedImages();
            }
        });

    }

    private deleteSelectedImages() {
        this.gridBuilder.removeItems(this.selectedImages);
        for (const image of this.selectedImages) {
            this.archiveService.deleteImage(this.id, image).then(obs => {
                obs.subscribe();
            });
        }
        this.selectedImages.length = 0;
    }

    private initAlbumData() {
        const setEditableData = (album: Album) => {
            this.editableData = {
                description: album.getDescription(),
                updatedDescription: album.getDescription(),
                title: album.getTitle(),
                updatedTitle: album.getTitle()
            };
        };

        try {
            if (!this.archiveService.isArchiveLoaded()) {
                throw Error();
            }
            this.album = this.archiveService.archive.getAlbum(this.id);
            setEditableData(this.album);
            this.loaded = true;
            this.loadImages();
        } catch (e) {
            this.archiveService.loadAlbum(this.id).subscribe(data => {
                this.album = new Album(data);
                setEditableData(this.album);
                this.loaded = true;
                this.loadImages();
            });
        }
    }

    private loadImages() {
        this.archiveService.getAlbumEntries(this.id, (images) => {
            images.orderByDate = this.album.orderByDate;
            this.gridBuilder.images = images;
            this.gridBuilder.build();
        });
    }

    private addImages() {
        this.fileTransferService.upload(this.id, this.addWatermarks, () => {
            this.rebuildGridEnd();
        });
    }

    rebuildGridEnd() {
        this.archiveService.getAlbumEntries(this.id, (images: ImageCollection) => {
            let index = 0;
            while (index < this.gridBuilder.images.size()) {
                if (this.gridBuilder.images.getImage(index).id !== images.getImage(index).id) {
                    break;
                }
                index++;
            }

            this.gridBuilder.images = images;
            this.gridBuilder.images.orderByDate = this.album.orderByDate;

            const empty = this.gridBuilder.grid.rows.length === 0;
            if (empty) {
                this.gridBuilder.build();
            } else {
                const rebuildFrom = Math.floor(index / 3);
                this.gridBuilder.rebuildFrom(rebuildFrom);
            }

        });
    }

    screenSizeListener() {
        this.updateContentWidth();
        this.gridBuilder.computeColumnCount();
        window.onresize = () => {
            this.updateContentWidth();
            this.updateImageMargins();
            this.gridBuilder.computeColumnCount();
        };
    }

    private scrollListener() {
        const wrapper = document.getElementById('wrapper');
        wrapper.onscroll = () => {
            this.gridBuilder.update(wrapper, this.contentWidth, this.imageMargins);
            const mainTitle = document.getElementById('edit-title');
            const position = mainTitle.getBoundingClientRect().top;
            this.showTitleInAppBar = position < 70;
        };
    }

    updateContentWidth() {
        this.contentWidth = document.getElementById('content').getBoundingClientRect().width;
        this.gridBuilder.displayWidth = this.contentWidth;
    }

    private updateImageMargins() {
        const newValue = window.innerWidth <= 1000 ? 1 : 3;
        const updated = newValue !== this.imageMargins;
        if (updated) {
            this.imageMargins = newValue;
        }
        this.gridBuilder.imageMargins = this.imageMargins;
    }

    selectedImagesCount(): string {
        const count = this.selectedImages.length;
        let word;
        if (count === 1) {
            word = 'izbrana';
        } else if (count === 2) {
            word = 'izbrani';
        } else if (count === 3 || count === 4) {
            word = 'izbrane';
        } else {
            word = 'izbranih';
        }
        return `${count} ${word}`;
    }

    queueLengthWordForm(length: number): string {
        if (length === 1) {
            return 'fotografija';
        } else if (length === 2) {
            return 'fotografiji';
        } else if (length === 3 || length === 4) {
            return 'fotografije';
        } else {
            return 'fotografij';
        }
    }

    keyboardListener() {
        window.onkeydown = (e) => {
            if (e.code === 'Delete' && this.selectedImages.length > 0) {
                e.preventDefault();
                this.openConfirmationDialog();
            }
        };
    }

    updateTitle(value) {
        this.editableData.updatedTitle = value;
        this.updateUnsavedChangesState();
    }

    updateDescription(value) {
        this.editableData.updatedDescription = value;
        this.updateUnsavedChangesState();
    }

    updateImageOrder(orderByDate: boolean) {
        if (orderByDate === this.album.orderByDate) {
            return;
        }
        this.album.orderByDate = orderByDate;
        this.gridBuilder.images.orderByDate = orderByDate;
        this.gridBuilder.rebuild();
        this.archiveManagementService.updateImageOrder(this.id, orderByDate).then(obs => obs.subscribe(() => {
            this.snackBar.open('Vrstni red je bil posodobljen', null, {
                duration: 3000
            });
        }));

    }

    exit() {
        this.updateUnsavedChangesState();
        this.router.navigate(['/fotografije/' + this.album.getId()]);
    }

    deleteAlbum() {
        this.archiveService.deleteAlbum(this.id).then(observable => {
            observable.subscribe(response => {
                this.router.navigate(['/fotografije']);
            });
        });
    }

    private updateUnsavedChangesState() {
        const changedTitle = this.editableData.title !== this.editableData.updatedTitle;
        const changedDescription = this.editableData.description !== this.editableData.updatedDescription;
        if (changedTitle || changedDescription) {
            this.updates.next();
        }
    }

    private editableUpdates() {
        this.updates.pipe(
            debounceTime(1000),
            switchMap(() => {
                return this.archiveManagementService.updateAlbumInformation(this.id, {
                    title: this.editableData.updatedTitle,
                    description: this.editableData.updatedDescription
                }).then(obs => {
                    obs.subscribe();
                });
            })
        ).subscribe();
    }
}
