export class Config {

    public static readonly BASE_WEB_CONTENT_URL = window.location.origin + '/';

    public static readonly BASE_ARCHIVE_API_URL = 'https://enajsta.azurewebsites.net/';
    // public static readonly BASE_ARCHIVE_API_URL = 'http://localhost:8080/';

    public static readonly API_ARCHIVE_ENTRIES = Config.BASE_ARCHIVE_API_URL + 'albums';
    public static readonly API_ALBUM_ENTRIES = Config.BASE_ARCHIVE_API_URL + 'album/';
    public static readonly API_ALBUM_INFO = Config.BASE_ARCHIVE_API_URL + 'albums/';
    public static readonly API_IMAGE = Config.BASE_ARCHIVE_API_URL + 'image/';
    public static readonly API_ALBUM_PREVIEW_IMAGE = Config.BASE_ARCHIVE_API_URL + 'image/preview/';
    public static readonly API_DELETE_IMAGE = Config.BASE_ARCHIVE_API_URL + 'delete/';
    public static readonly API_ALBUM = Config.BASE_ARCHIVE_API_URL + 'albums/';
    public static readonly API_UPLOAD_IMAGE = Config.BASE_ARCHIVE_API_URL + 'publish/image';
    public static readonly API_UPDATE_ALBUM = Config.BASE_ARCHIVE_API_URL + 'update';
    public static readonly API_UPDATE_ALBUM_IMAGE_ORDER = Config.BASE_ARCHIVE_API_URL + 'image-order';
    public static readonly API_AUTHORIZE_DOWNLOAD = Config.BASE_ARCHIVE_API_URL + 'auth';
}
