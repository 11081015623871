/* tslint:disable:no-trailing-whitespace variable-name */

import {Album} from './Album';

export class YearSection {

    private readonly _albums;
    private readonly year;

    readonly albums: Album[];

    constructor(year: string, albums) {
        this._albums = albums;
        this.year = year;
        this.albums = this.getAlbums();

    }

    private getAlbums() {
        const albums = new Array<Album>();
        for (const archiveEntry of this._albums) {
            albums.push(new Album(archiveEntry));
        }
        return albums;
    }

    public getYear(): string {
        return this.year;
    }

}
