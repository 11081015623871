/* tslint:disable:no-trailing-whitespace */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

const endpoint = 'https://sheets.googleapis.com/v4/spreadsheets';
const sheetId = '1We_Qk3Rur1UwCgjNGKlBTMBjijPB6VxQV2TzTgjxE-U';
const range = 'A2:F20';
const apiKey = 'AIzaSyDQQkGb_tLVCK4H4AXJktA1U9sVOsiU_h4';

interface Data {
    range: string;
    majorDimension: string;
    values: string[][];
}

export interface FormattedData {
    Vod: string;
    Starost: string;
    Termin: string;
    Vodnik: string;
    Mail: string;
    Skupina: string;
}


@Injectable({
    providedIn: 'root'
})
export class AppRepository implements DataSource<FormattedData> {

    constructor(private http: HttpClient) {

    }

    private static formatData(data: string[][]): FormattedData[] {
        const columns: FormattedData[] = [];
        for (const row of data) {
            const vod: FormattedData = {
                Vod: row[0],
                Vodnik: row[1],
                Starost: row[2],
                Termin: row[3],
                Mail: row[4],
                Skupina: row[5],
            };
            columns.push(vod);
        }
        return columns;
    }

    connect(collectionViewer: CollectionViewer): Observable<FormattedData[] | readonly FormattedData[]> {
        const url = `${endpoint}/${sheetId}/values/${range}?key=${apiKey}`;
        return this.http.get<Data>(url).pipe(
            map((data) => {
                return AppRepository.formatData(data.values);
            })
        );
    }

    disconnect(collectionViewer: CollectionViewer): void {
    }
}


