import {Component} from '@angular/core';
import {Archive} from '../model/Archive';
import {ArchiveService} from '../../archive.service';
import {YearSection} from '../model/YearSection';
import {AuthService} from '../../../main/pages/auth/auth.service';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-photos',
    templateUrl: './photos.component.html',
    styleUrls: ['./photos.component.css']
})
export class PhotosComponent {

    constructor(
        private archiveService: ArchiveService,
        private authService: AuthService,
        private titleService: Title
    ) {
        titleService.setTitle('Fotografije | XI. SNOUB');
        this.archiveService.loadArchive().subscribe((archive: Archive) => {
            this.archive = archive;
            this.yearSections = this.archive.getYearSections();
            this.loaded = true;
            this.editor = authService.isLoggedIn;
        });
    }


    loaded = false;
    archive: Archive;
    yearSections: YearSection[] = [];
    editor = false;

}
