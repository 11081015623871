<div id="dropzone" class="drop-area" [ngClass]="{'drag-in-progress': dragInProgress}">

  <div class="drop-message">
    <div class="cloud"></div>
    <div class="arrow"></div>
    <h1>Spusti slike</h1>
  </div>


</div>

