import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-appbar',
    templateUrl: './appbar.component.html',
    styleUrls: ['./appbar.component.css'],

})
export class AppbarComponent implements OnInit {


    constructor(private titleService: Title) {
        titleService.setTitle('Rod XI. SNOUB | Taborniki');
    }
    ngOnInit(): void {
        try {
            this.hideMobile();
            this.backdrop();
        } catch (e) {
        }
    }

    backdrop() {
        window.onscroll = () => {
            if (window.scrollY === 0) {
                const element = document.getElementById('toolbar');
                element.classList.add('on-top');
            } else {
                const element = document.getElementById('toolbar');
                element.classList.remove('on-top');
            }
        };


        window.addEventListener('scroll', buttonBackdrop, false);
        window.addEventListener('resize', buttonBackdrop, false);

        function buttonBackdrop() {
            if (window.scrollY === 0 && window.innerHeight < 800) {
                const element = document.getElementById('button-backdrop');
                element.classList.add('button-backdrop');
            } else {
                const element = document.getElementById('button-backdrop');
                element.classList.remove('button-backdrop');
            }
        }

        buttonBackdrop();
    }

    hideMobile() {
        let lastScrollTop = 0;
        window.addEventListener('scroll', function() {
            const st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                if (this.window.innerWidth <= 800) {
                    document.getElementById('toolbar').style.transform = 'translateY(-67px)';
                    document.getElementById('toolbar').style.transition = '300ms ease-out';
                }
            } else {
                document.getElementById('toolbar').style.transform = 'translateY(0px)';
                document.getElementById('toolbar').style.transition = '300ms ease-in';
            }
            lastScrollTop = st <= 0 ? 0 : st;

        }, false);
    }
}
