/* tslint:disable:no-trailing-whitespace */


import {YearSection} from './YearSection';

export class Archive {

    private readonly json;

    constructor(json) {
        this.json = json;
    }

    get rawData() {
        return this.json;
    }

    public getAlbum(id: string) {
        for (const section of this.getYearSections()) {
            for (const album of section.albums) {
                if (album.getId() === id) {
                    return album;
                }
            }
        }
    }

    public getYearSections(): Array<YearSection> {
        const sections = new Array<YearSection>();
        for (const archiveEntry of Object.keys(this.json)) {
            sections.push(new YearSection(archiveEntry, this.json[archiveEntry]));
        }
        sections.sort(((a, b) => {
            if (a.getYear() > b.getYear()) {
                return -1;
            } else if (a.getYear() < b.getYear()) {
                return 1;
            } else {
                return 0;
            }
        }));
        return sections;
    }
}
