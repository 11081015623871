import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {CalendarComponent} from './pages/calendar/calendar.component';
import {ContactComponent} from './pages/kontakt/contact.component';
import {AboutPageComponent} from './pages/about-page/about-page.component';
import {JoinUsPageComponent} from './pages/join-us-page/join-us-page.component';
import {VodiComponent} from './pages/vodi/vodi.component';
import {ReferencesComponent} from './pages/references/references.component';
import {TermsOfUseComponent} from './pages/terms-of-use/terms-of-use.component';
import {InformationComponent} from './pages/informations/information.component';
import {TaborComponent} from './pages/tabor/tabor.component';
import {KrojComponent} from './pages/kroj/kroj.component';
import {NgModule} from '@angular/core';
import {TaxComponent} from './pages/tax/tax.component';
import {TransparencyComponent} from './pages/transparency/transparency.component';

const appRoutes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'koledar', component: CalendarComponent},
    {path: 'kontakt', component: ContactComponent},
    {path: 'kdo-smo', component: AboutPageComponent},
    {path: 'pridruzi-se', component: JoinUsPageComponent},
    {path: 'vodi', component: VodiComponent},
    {path: 'povezave', component: ReferencesComponent},
    {path: 'pravno-obvestilo', component: TermsOfUseComponent},
    {path: 'informations', component: InformationComponent},
    {path: 'taborni-prostor', component: TaborComponent},
    {path: 'uniforma', component: KrojComponent},
    {path: 'informacije', component: InformationComponent},
    {path: 'prispevek', component: TaxComponent},
    {path: 'transparentnost-delovanja', component: TransparencyComponent},
    // {path: 'prijavnica-na-tabor-2020', component: RaznoComponent},

];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})

export class MainRoutingModule {

}
