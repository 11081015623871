import {Component, OnInit} from '@angular/core';


interface Document {
  path: string;
  name: string;
  icon: string;
}

@Component({
  selector: 'app-transparency',
  templateUrl: './transparency.component.html',
  styleUrls: ['./transparency.component.scss']
})
export class TransparencyComponent implements OnInit {

  constructor() {
  }

  documents: Document[] = [
    {
      name: 'Predstavitev XI. SNOUB',
      path: 'assets/documents/transparentnost/Predstavitev_XI_SNOUB.pdf',
      icon: 'picture_as_pdf',
    },
    {
      name: 'Bilanca stanja 2022',
      path: 'assets/documents/transparentnost/Bilanca_stanja_2022.pdf',
      icon: 'picture_as_pdf',
    },
    {
      name: 'Dodatni podatki 2022',
      path: 'assets/documents/transparentnost/Dodatni_podatki_2022.pdf',
      icon: 'picture_as_pdf',
    },
    {
      name: 'Društvo v javnem interesu - odločba 2019',
      path: 'assets/documents/transparentnost/Druçtvo_v_javnem_interesu_odloŸba_2019.pdf',
      icon: 'picture_as_pdf',
    },
    {
      name: 'Izkaz poslovnega izida 2022',
      path: 'assets/documents/transparentnost/Izkaz_poslovnega_izida_2022.pdf',
      icon: 'picture_as_pdf',
    },
    {
      name: 'Poročilo starešine 2021',
      path: 'assets/documents/transparentnost/PoroŸilo_stareçine_2021.pdf',
      icon: 'picture_as_pdf',
    },
    {
      name: 'Poročilo starešine 2022',
      path: 'assets/documents/transparentnost/PoroŸilo_stareçine_2022.pdf',
      icon: 'picture_as_pdf',
    },
    {
      name: 'Poročilo načelnika 2021',
      path: 'assets/documents/transparentnost/Poroźilo_naźelnika_2021.pdf',
      icon: 'picture_as_pdf',
    },
    {
      name: 'Prihodki  odhodki 2022',
      path: 'assets/documents/transparentnost/Prihodki_odhodki2022.xlsx',
      icon: 'table_chart',
    },
    {
      name: 'Statut XI SNOUB 2018',
      path: 'assets/documents/transparentnost/Statut_XI_SNOUB_2018.pdf',
      icon: 'picture_as_pdf',
    },
  ];

  ngOnInit(): void {
  }
}


