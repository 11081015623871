<app-drop-files-surface></app-drop-files-surface>

<div class="appbar" [ngClass]="{'shadow' : showTitleInAppBar}">
  <button mat-icon-button (click)="exit()">
    <mat-icon>arrow_back</mat-icon>
  </button>

  <span [ngClass]="{'visible' : showTitleInAppBar}" class="h-title">
      {{editableData.updatedTitle}}
    </span>


  <div class="options">
    <button mat-icon-button [matMenuTriggerFor]="imageOrderMenu">
      <mat-icon>sort</mat-icon>
    </button>
    <mat-menu #imageOrderMenu="matMenu">
      <button disabled mat-menu-item>Razvrsti slike glede na:</button>
      <button (click)="updateImageOrder(true)" mat-menu-item>datum nastanka</button>
      <button (click)="updateImageOrder(false)" mat-menu-item>zaporedje nalaganja</button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="deleteAlbum()">Izbriši album</button>
    </mat-menu>
  </div>
</div>

<div *ngIf="selectedImages.length > 0" class="selection-toolbar">
  <button mat-icon-button (click)="selectedImages.length = 0">
    <mat-icon>close</mat-icon>
  </button>
  <p>{{selectedImagesCount()}}</p>
  <button class="bin" mat-icon-button (click)="openConfirmationDialog()">
    <mat-icon>delete_outline</mat-icon>
  </button>
</div>

<div id="wrapper" class="wrapper">
  <div class="title" id="edit-title">
    <input id="title" maxlength="45" #title (keyup)="updateTitle(title.value.trim())" type="text" placeholder="Naslov"
           [value]="editableData.title" autocomplete="off" autocapitalize="sentences">
  </div>
  <div class="description">
    <div id="description" #description (keyup)="updateDescription(description.innerText.trim())" class="textarea"
         autocapitalize="sentences"
         contenteditable="true">{{editableData.description}}</div>
    <p class="placeholder">Dodaj opis</p>
  </div>
  <div class="add-photos-options">
    <button onclick="document.getElementById('file-input').click();" mat-raised-button>Dodaj fotografije</button>
    <input type="file" id="file-input" style="display: none;" (change)="addImagesFromExplorer($event)"
           multiple="multiple" accept="image/*">
    <p>Ali pa jih povleci v to okno</p>
  </div>
  <div id="content" class="content">
    <div *ngFor="let row of gridBuilder.grid.rows" class="row">
      <img
        *ngFor="let image of row.images"
        [ngStyle]="{'height': row.getRowHeight(contentWidth, imageMargins) + 'px', 'margin':  imageMargins + 'px'}"
        [ngClass]="{'selected': selectedImages.includes(image.id)}"
        [id]="image.id"
        [src]="image.img.src"
        (click)="select(image.id)">
    </div>
  </div>
</div>

<div class="watermark-switch">
  <mat-slide-toggle [checked]="addWatermarks" (change)="addWatermarks = !addWatermarks" color="accent">Dodaj vodni žig
  </mat-slide-toggle>
</div>

<div class="upload-progress" [ngClass]="{'visible' : fileTransferService.uploadState.active}">
  <mat-progress-bar
    *ngIf="fileTransferService.uploadState.currentUploadProgress < 100"
    mode="determinate"
    [value]="fileTransferService.uploadState.currentUploadProgress"
    color="accent">
  </mat-progress-bar>
  <mat-progress-bar
    *ngIf="fileTransferService.uploadState.currentUploadProgress === 100"
    mode="indeterminate"
    color="accent">
  </mat-progress-bar>
  <div class="wrap">
    <mat-icon>cloud_queue</mat-icon>
    <div class="info">
      <p class="file">{{fileTransferService.uploadState.currentlyUploading}}</p>
      <p *ngIf="fileTransferService.uploadState.queueLength > 0" class="queue">
        Še
        <span>{{fileTransferService.uploadState.queueLength}}</span>&#160;{{queueLengthWordForm(fileTransferService.uploadState.queueLength)}}
      </p>
    </div>
  </div>
</div>
