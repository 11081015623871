/* tslint:disable:no-trailing-whitespace */
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ArchiveSearchEngine, Result} from '../../../archive-search-engine/ArchiveSearchEngine';
import {ArchiveService} from '../../../archive.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {ShareDialogComponent} from '../../share-dialog/share-dialog.component';
import {MatDialog} from '@angular/material/dialog';

// noinspection JSMethodCanBeStatic,DuplicatedCode
@Component({
    selector: 'app-archive-appbar',
    templateUrl: './archive-appbar.component.html',
    styleUrls: ['./archive-appbar.component.css']
})

export class ArchiveAppbarComponent implements OnInit {

    constructor(public archiveService: ArchiveService,
                private router: Router,
                private route: ActivatedRoute,
                public dialog: MatDialog,
                private changeDetectorRef: ChangeDetectorRef
    ) {
        this.searchEngine.index(archiveService.archive.rawData);
    }

    @Input() editor = false;

    searchResults: SearchResult[] = [];
    focusedResult: SearchResult = null;

    private searchEngine = new ArchiveSearchEngine();

    private lastQuery = null;


    ngOnInit() {
        this.keyboardListener();
        this.handleSearchBarSizeChanges();
    }

    getValue(target: EventTarget): string {
        return (target as HTMLInputElement).value;
    }

    focusFirstResult() {
        this.focusedResult = this.searchResults[0];
    }

    search(query: string) {
        if (this.lastQuery === query) {
            return;
        }
        const results = this.searchEngine.search(query);
        const liableSortedResults = this.prioritizeResults(results, 4);
        this.buildSearchResults(liableSortedResults);
        this.focusFirstResult();
        this.lastQuery = query;
    }

    private buildSearchResults(resultIds: string[]) {
        this.searchResults.length = 0;
        for (const id of resultIds) {
            const album = this.archiveService.archive.getAlbum(id);
            this.searchResults.push({
                id,
                category: album.prettyCategory,
                date: album.getPrettyDate(),
                imageUrl: album.getPreviewImageUrl(),
                title: album.getTitle()
            });
        }
    }

    private prioritizeResults(searchResults: Result[], maxResults: number): string[] {
        searchResults.sort((a: Result, b: Result) => {
            if (a.score < b.score) {
                return 1;
            } else if (a.score > b.score) {
                return -1;
            } else {
                return 0;
            }
        });

        const prioritizedResults: string[] = [];

        for (let i = 0; i < maxResults && i < searchResults.length; i++) {
            prioritizedResults.push(searchResults[i].id);
        }
        return prioritizedResults;
    }

    keyboardListener() {
        const element = document.getElementById('search-input');
        element.onkeydown = (e) => {
            if (e.code === 'ArrowDown') {
                e.preventDefault();
                this.focusDown();
            } else if (e.code === 'ArrowUp') {
                e.preventDefault();
                this.focusUp();
            } else if (e.code === 'Enter') {
                e.preventDefault();
                this.selectFocused();
            } else if (e.code === 'Escape') {
                e.preventDefault();
                element.blur();
            }
        };

        document.onkeydown = (e) => {
            if (e.ctrlKey && e.code === 'KeyF') {
                e.preventDefault();
                element.focus();
            }
        };
    }

    private focusDown() {
        const currentFocus = this.searchResults.indexOf(this.focusedResult);
        if (currentFocus < 0) {
            return;
        }
        if (currentFocus === this.searchResults.length - 1) {
            this.focusedResult = this.searchResults[0];
        } else {
            this.focusedResult = this.searchResults[currentFocus + 1];
        }
    }

    private focusUp() {
        const currentFocus = this.searchResults.indexOf(this.focusedResult);
        if (currentFocus < 0) {
            return;
        }
        if (currentFocus === 0) {
            this.focusedResult = this.searchResults[this.searchResults.length - 1];
        } else {
            this.focusedResult = this.searchResults[currentFocus - 1];
        }
    }

    private selectFocused() {
        if (window.innerWidth < 700) {
            return;
        }
        if (this.focusedResult !== null) {
            this.router.navigate([this.focusedResult.id], {relativeTo: this.route});
        }
    }

    private handleSearchBarSizeChanges() {
        // @ts-ignore
        const resizeObserver = new ResizeObserver(() => {
            this.changeDetectorRef.detectChanges();
        });
        const searchbar = document.getElementById('searchbar');
        resizeObserver.observe(searchbar);
    }

    searchResultsOffset() {
        const searchbar = document.getElementById('searchbar');
        const position = searchbar.getBoundingClientRect();
        return {
            'left.px': position.left,
            'top.px': position.bottom,
            'width.px': position.width
        };
    }

    share(): void {
        this.dialog.open(ShareDialogComponent, {
            width: '450px',
            data: {title: 'Deli povezavo', album: '', image: ''},
            panelClass: 'basic-dialog-container',
            autoFocus: false
        });
    }
}


interface SearchResult {
    id: string;
    title: string;
    date: string;
    category: string;
    imageUrl: string;
}
