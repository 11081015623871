<div class="wrapper">
  <div class="header">
    <h1 class="title">Prenesi izvirnik</h1>
    <button tabindex="-1" class="close" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="auth">
    <mat-form-field class="field">
      <mat-label>Geslo</mat-label>
      <input id="input" autofocus tabindex="0" [(ngModel)]="password" type="password" matInput autocomplete="off">
    </mat-form-field>
  </div>

  <div class="bottom">
    <button mat-flat-button color="accent" (click)="requestImage()">Prenesi</button>
  </div>
</div>
