<div style="position: relative; height: 100%; overflow: hidden">
  <div *ngIf="!loaded">
    <mat-progress-bar class="loading" color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
  <app-archive-appbar *ngIf="loaded" [editor]="editor"></app-archive-appbar>
  <div class="wrapper">
    <div *ngIf="loaded" class="gallery">
      <div *ngFor="let yearSection of yearSections" class="year-section">
        <div class="year">
          <h1>{{yearSection.getYear()}}</h1>
        </div>
        <div>
          <div *ngFor="let album of yearSection.albums" [routerLink]="album.getId()" class="album unselectable">
            <div mat-ripple class="preview"
                 [ngStyle]="{'background-image': 'url(' + album.getPreviewImageUrl() + ')' }">
            </div>
            <div class="data">
              <div class="vert">
                <h2>{{album.getTitle()}}</h2>
                <h3>{{album.getPrettyDate()}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
