<app-appbar></app-appbar>
<div class="wrapper">
  <h1 class="title mat-display-3">Koledar taborniških dogodkov</h1>
  <div class="download">
    <h4>Prenesi razpored dogodkov:</h4>
    <a mat-flat-button href="/assets/documents/Koledar%20rodovih%20akcij%202021-2022.pdf"
       download="Koledar rodovih akcij 2021-2022.pdf">Koledar rodovih akcij.pdf</a>

  </div>
  <div class="calendar">
    <!--    <iframe-->
    <!--      src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FBelgrade&amp;src=dXJpaWlmbnBxa3VrYnRnazdhY3VoNWZmMWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23F4511E&amp;showTitle=0&amp;showNav=1&amp;showDate=1&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0"-->
    <!--      style="border-width:0" width="800" height="600" frameborder="0" scrolling="no"></iframe>-->

    <iframe src="https://calendar.google.com/calendar/embed?src=xisnoub%40gmail.com&ctz=Europe%2FAmsterdam"
            style="border: 0" width="800" height="600" frameborder="0" scrolling="no"></iframe>

  </div>
</div>
<app-footer></app-footer>
