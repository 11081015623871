<app-appbar></app-appbar>
<div class="wrapper">

  <h1 class="title title-desktop mat-display-3">Transparentnost delovanja</h1>
  <h1 class="title title-mobile mat-display-1">Transparentnost delovanja</h1>

  <div class="documents">
    <h2 class="document-title">Ključni dokumenti</h2>
    <div class="doc-list">
      <a *ngFor="let doc of documents" class="document"
         mat-stroked-button
         [href]="doc.path"
         target="_blank">
        <mat-icon>{{doc.icon}}</mat-icon>
        {{doc.name}}
      </a>
    </div>

  </div>
</div>

<div class="squiggle">
  <svg aria-hidden="true" width="100%" height="8" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <pattern id="a" width="91" height="8" patternUnits="userSpaceOnUse">
      <g clip-path="url(#clip0_2426_11367)">
        <path
          d="M114 4c-5.067 4.667-10.133 4.667-15.2 0S88.667-.667 83.6 4 73.467 8.667 68.4 4 58.267-.667 53.2 4 43.067 8.667 38 4 27.867-.667 22.8 4 12.667 8.667 7.6 4-2.533-.667-7.6 4s-10.133 4.667-15.2 0S-32.933-.667-38 4s-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0-10.133-4.667-15.2 0-10.133 4.667-15.2 0"
          stroke="rgba(0,0,0,0.3)" stroke-linecap="square"></path>
      </g>
    </pattern>
    <rect width="100%" height="100%" fill="url(#a)"></rect>
  </svg>
</div>

<iframe id="odprti-racuni" frameborder="0" src="https://odprtiracuni-nvo.djnd.si/21/"></iframe>
