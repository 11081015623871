import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainModule} from './sections/main/main.module';
import {AppComponent} from './app-component/app.component';
import {ArchiveModule} from './sections/archive/archive.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MainModule,
        ArchiveModule
    ],
    bootstrap: [AppComponent],
})

export class AppModule {
}
