/* tslint:disable:no-trailing-whitespace */
// noinspection JSMethodCanBeStatic

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ArchiveService} from '../../../../archive.service';

export interface DialogData {
    album: string;
    image: string;
}

@Component({
    selector: 'app-image-download-dialog',
    templateUrl: './download-dialog.component.html',
    styleUrls: ['./download-dialog.component.css']
})
export class DownloadDialogComponent implements OnInit {

    filename = 'Image.jpg';
    password = '';

    constructor(
        public dialogRef: MatDialogRef<DownloadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private archiveService: ArchiveService) {
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        document.getElementById('input').addEventListener('keydown', (event) => {
            if (event.code === 'Enter') {
                this.requestImage();
            }
        });
    }

    requestImage() {
        const authRequest = this.archiveService.authorizeDownload(this.password);
        authRequest.subscribe(
            token => this.download(token),
            error => this.handleError(error)
        );
    }

    private download(token) {
        // todo save token and check if it is still valid on next request
        this.archiveService.downloadOriginalImage(this.data.album, this.data.image, token.value);
        this.dialogRef.close();
    }

    private handleError(error) {
        this.password = '';
    }
}
