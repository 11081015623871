import {Component, OnInit, ComponentFactoryResolver, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ContactFormComponent} from './contact-form/contact-form.component';
import {ContactFormDirective} from './contact-form/contact-form.directive';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})


export class ContactComponent implements OnInit {

    @ViewChild(ContactFormDirective, {static: true}) formHost: ContactFormDirective;

    constructor(private _snackBar: MatSnackBar,
                private componentFactoryResolver: ComponentFactoryResolver
    ) {
    }


    ngOnInit(): void {
        this.loadComponent();
    }


    loadComponent() {

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ContactFormComponent);

        const viewContainerRef = this.formHost.viewContainerRef;
        viewContainerRef.clear();

        const componentRef = viewContainerRef.createComponent<ContactFormComponent>(componentFactory);
    }


    receive() {

    }

    send() {

    }

    onSent(sent: boolean) {
        if (sent) {
            this.send();
            this.receive();
        }
    }
}
