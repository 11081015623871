<div class="wrapper">
  <div class="title">
    <svg routerLink="/"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 1000 1000" xml:space="preserve" width="50"
         height="50">
      <g id="g839">
                    <g id="g825">
                        <path class="st1"
                              d="M861.29,62.09c42.84,0,77.69,34.85,77.69,77.69v520.11h-77.1c-42.84,0-77.69-34.85-77.69-77.69V62.09H861.29     M861.29,26.06H748.16v556.13c0,62.8,50.91,113.71,113.71,113.71H975V139.77C975,76.97,924.09,26.06,861.29,26.06L861.29,26.06z"
                              id="path817"/>
                      <path class="st1"
                            d="M205.52,436.28c-8.51-11.19-16.48-22.82-23.87-34.85L26.53,695.74l294.31-155.12    c-12.03-7.39-23.66-15.35-34.85-23.87C255.66,493.67,228.59,466.6,205.52,436.28z"
                            id="path819"/>
                      <path class="st1"
                            d="M516.97,285.82c8.51,11.19,16.47,22.83,23.86,34.87L695.95,26.37L401.72,181.45    c12.03,7.39,23.65,15.36,34.83,23.88C466.86,228.41,493.91,255.49,516.97,285.82z"
                            id="path821"/>
                      <path class="st1"
                            d="M512.76,348.27c-4.66-8.85-9.72-17.44-15.12-25.78c-25.36-39.11-58.67-72.45-97.77-97.82    c-8.39-5.44-17.04-10.53-25.93-15.22l-12.66-6.67L26.55,26.35l176.4,334.67l6.76,12.83c4.66,8.85,9.72,17.44,15.12,25.78    c25.36,39.11,58.67,72.45,97.77,97.82c8.39,5.44,17.04,10.53,25.93,15.22l12.66,6.67l334.72,176.42l-176.4-334.67L512.76,348.27z     M289.56,344.98h54.72l16.91-52.04l16.91,52.04h54.72l-44.27,32.17l16.91,52.04l-44.27-32.17l-44.27,32.17l16.91-52.04    L289.56,344.98z M115.86,115.65l206.81,109c-39.11,25.36-72.45,58.67-97.82,97.77L115.86,115.65z M606.62,606.46l-206.81-109    c39.11-25.36,72.45-58.67,97.82-97.77L606.62,606.46z"
                            id="path823"/>
                    </g>
        <g id="g837">
                        <path class="st1"
                              d="M25,943.01l21.02-26.12c15.61,14.11,33.03,24.92,55.25,24.92c20.12,0,33.03-11.11,33.03-26.72v-0.3    c0-13.21-6.9-21.92-40.23-34.23c-39.93-15.01-60.65-29.73-60.65-63.35v-0.6c0-34.53,27.62-58.85,66.66-58.85    c24.32,0,46.84,7.8,66.05,24.62L146.3,809.1c-14.41-11.71-30.33-19.82-47.14-19.82c-18.91,0-30.62,10.51-30.62,24.32v0.3    c0,14.71,7.8,22.52,43.53,36.33c39.03,15.01,57.05,31.23,57.05,61.85v0.6c0,36.93-28.82,60.65-68.16,60.65    C73.94,973.34,47.82,964.03,25,943.01z"
                              id="path827"/>
          <path class="st1"
                d="M204.84,760.46h32.43l92.48,140.22V760.46h33.93v210.17h-29.42l-95.18-144.42v144.42h-34.23V760.46z"
                id="path829"/>
          <path class="st1"
                d="M401.5,867.35v-3c0-62.45,38.43-107.19,93.38-107.19c54.65,0,92.78,44.44,92.78,106.59v3    c0,62.45-38.43,107.19-93.38,107.19C439.63,973.94,401.5,929.5,401.5,867.35z M551.62,866.75v-2.1    c0-45.04-24.02-75.36-57.35-75.36c-33.03,0-56.75,29.73-56.75,75.06v2.1c0,45.04,24.32,75.36,57.35,75.36    C527.9,941.81,551.62,912.09,551.62,866.75z"
                id="path831"/>
          <path class="st1"
                d="M622.77,890.47V760.46h34.83v129.71c0,33.93,16.51,51.64,43.53,51.64c26.42,0,43.24-17.11,43.24-50.44V760.46    h34.53v128.81c0,56.45-30.93,84.67-78.37,84.67C653.7,973.94,622.77,945.42,622.77,890.47z"
                id="path833"/>
          <path class="st1"
                d="M822.13,760.46h75.06c22.82,0,42.03,6.3,53.75,18.01c9.31,9.31,14.11,21.02,14.11,35.43v0.9    c0,25.82-15.01,39.33-30.92,47.14c23.12,8.11,39.93,21.92,39.93,50.14v1.2c0,36.93-30.02,57.35-74.76,57.35h-77.17V760.46z     M930.22,819.61c0-17.71-12.91-27.92-35.43-27.92h-38.43v58.25h36.33c22.82,0,37.53-10.21,37.53-29.72V819.61z M898.69,879.36    h-42.33v60.05h43.84c24.02,0,39.03-10.51,39.03-29.73v-0.6C939.23,890.77,925.11,879.36,898.69,879.36z"
                id="path835"/>
                    </g>
                </g>
            </svg>
  </div>
  <div id="searchbar" class="searchbar" [ngClass]="{'with-results': searchResults && searchResults.length > 0}">
    <mat-icon>search</mat-icon>
    <form [formGroup]="">
      <input
        (keyup)="search(getValue($event.target))"
        autocomplete="off"
        id="search-input"
        type="text"
        placeholder="Išči fotografije ...">
    </form>
    <div class="search-results" [ngStyle]="searchResultsOffset()">
      <div mat-ripple class="result" *ngFor="let result of searchResults"
           [routerLink]="result.id"
           [id]="result"
           [ngClass]="{'selected': focusedResult.id === result.id}">
        <div class="result-content">
          <img [src]="result.imageUrl">
          <div class="description">
            <p class="s-title">{{result.title}}</p>
            <p class="s-date">{{result.date}}</p>
          </div>
          <div class="s-category">
            <p class="s-category">{{result.category}}</p>
          </div>
        </div>
      </div>
      <div class="spacer" *ngIf="searchResults && searchResults.length > 0" style="height: 5px"></div>
    </div>
  </div>
  <div class="options">
    <button class="new" *ngIf="editor" mat-stroked-button routerLink="/archive/edit">
      Nov Album
    </button>
    <button mat-icon-button (click)="share()" class="share">
      <mat-icon>share</mat-icon>
    </button>
  </div>
  <button class="share_fab" mat-fab color="white" (click)="share()">
    <mat-icon>share</mat-icon>
  </button>
  <div class="backdrop" *ngIf="searchResults && searchResults.length > 0"></div>
</div>

