/* tslint:disable:no-trailing-whitespace */

import {Config} from '../../../../global/Config';
import {eventCategories} from '../../util/EventCategories';

export class Album {

    private json;

    constructor(json) {
        this.json = json;
    }

    public getTitle(): string {
        return this.json.title;
    }

    public getDescription(): string {
        return this.json.description;
    }

    public getDate(): string {
        return this.json.date;
    }

    public get orderByDate(): boolean {
        return this.json.sortByDate;
    }

    public set orderByDate(value: boolean) {
        this.json.sortByDate = value;
    }

    public getPrettyDate(): string {
        const date = new Date(this.json.date);
        return date.getDate() + '. ' + (date.getMonth() + 1) + '. ' + date.getFullYear();
    }


    get category(): string {
        return this.json.category;
    }

    get prettyCategory(): string {
        const id = this.category;
        for (const category of eventCategories) {
            if (category.id.toString() === id) {
                return category.viewValue;
            }
        }
    }

    get location(): string {
        return this.json.location;
    }

    public getId(): string {
        return this.json.id;
    }

    public getPreviewImageUrl(): string {
        return Config.API_ALBUM_PREVIEW_IMAGE + this.getId();
    }

}
