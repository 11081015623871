import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NewAlbumComponent} from './create-album/new-album.component';
import {EditAlbumComponent} from './edit-album/edit-album.component';

const routes: Routes = [
    {path: 'archive/edit', component: NewAlbumComponent},
    {path: 'archive/edit/:id', component: EditAlbumComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ArchiveManagementRoutingModule {
}
