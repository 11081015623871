/* tslint:disable:no-trailing-whitespace */
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data) {
        this.imagesWord = this.shapeWord(data.count);
    }

    imagesWord;

    private shapeWord(count: number): string {
        if (count === 1) {
            return 'sliko';
        } else if (count === 2) {
            return 'sliki';
        } else if (count === 3 || count === 4) {
            return 'slike';
        } else {
            return 'slik';
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
