import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

import { LoginComponent } from './login/login.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { MatSnackBarModule } from '@angular/material/snack-bar';


const config = {
  apiKey: 'AIzaSyDQQkGb_tLVCK4H4AXJktA1U9sVOsiU_h4',
  authDomain: 'enajsta.firebaseapp.com',
  databaseURL: 'https://enajsta.firebaseio.com',
  projectId: 'enajsta',
  storageBucket: 'enajsta.appspot.com',
  messagingSenderId: '276581524805',
  appId: '1:276581524805:web:b2e9f41e16fdcdfeec4a2c',
  measurementId: 'G-ZGZJ8JN2DX'
};

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
    MatSnackBarModule

  ]
})
export class AuthModule { }
