<div class="wrapper">
  <div class="header">
    <h1 class="title">{{data.title}}</h1>
    <button  class="close" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>
    <div class="social unselectable">
      <div class="option" matRipple (click)="facebook()">
        <svg class="facebook-logo" width="32px" height="32px" viewBox="0 0 48 48">
          <path
            d="M40 4H8C5.79 4 4.02 5.79 4.02 8L4 40c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-2 4v6h-4c-1.1 0-2 .89-2 2v4h6v6h-6v14h-6V26h-4v-6h4v-5c0-3.87 3.13-7 7-7h5z"></path>
        </svg>
        <span>Facebook</span>
      </div>
      <div class="option" matRipple (click)="twitter()">
        <svg class="twitter-logo" width="32px" height="32px" viewBox="0 0 48 48">
          <path
            d="M40 4H8C5.79 4 4.02 5.79 4.02 8L4 40c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V8c0-2.21-1.79-4-4-4zm-4.58 14.66c-.13 9.24-6.03 15.57-14.85 15.96-3.64.16-6.27-1.01-8.57-2.46 2.69.43 6.02-.64 7.8-2.17-2.64-.26-4.19-1.6-4.93-3.75.76.13 1.56.1 2.29-.06-2.38-.8-4.07-2.27-4.16-5.34.67.3 1.36.59 2.29.64-1.78-1.01-3.09-4.71-1.59-7.16 2.64 2.9 5.82 5.26 11.03 5.58-1.31-5.59 6.12-8.63 9.21-4.87 1.31-.25 2.38-.75 3.41-1.29-.42 1.3-1.24 2.21-2.23 2.94 1.09-.15 2.06-.41 2.88-.82-.5 1.05-1.62 2-2.58 2.8z"></path>
        </svg>
        <span>Twitter</span>
      </div>
      <div *ngIf="nativeShare" class="option" matRipple (click)="native()">
        <svg
          class="more-icon"
          width="32px"
          height="32px"
          viewBox="0 0 48 48">
          <path
            id="path2"
            style="stroke-width:0.666667"
            d="M 15.863281 2.6640625 C 12.262192 2.6623539 8.6611885 2.663659 5.0605469 2.6796875 C 3.4955848 2.7898792 2.4796554 4.3801926 2.6796875 5.8613281 C 2.6845422 12.887249 2.6516269 19.91411 2.6796875 26.939453 C 2.7900056 28.504474 4.3753235 29.536267 5.8613281 29.333984 C 12.887249 29.324852 19.91411 29.352367 26.939453 29.320312 C 28.504474 29.209995 29.536267 27.624677 29.333984 26.138672 C 29.324852 19.112751 29.352367 12.08589 29.320312 5.0605469 C 29.210323 3.7340598 27.997594 2.639221 26.666016 2.6660156 C 23.065085 2.670583 19.46437 2.6657711 15.863281 2.6640625 z M 12.667969 11.833984 L 16.833984 16 L 12.667969 20.166016 L 12.667969 16.833984 L 7.6679688 16.833984 L 7.6679688 15.166016 L 12.667969 15.166016 L 12.667969 11.833984 z M 16.833984 11.833984 L 24.332031 11.833984 L 24.332031 13.5 L 16.833984 13.5 L 16.833984 11.833984 z M 19.332031 15.166016 L 24.332031 15.166016 L 24.332031 16.833984 L 19.332031 16.833984 L 19.332031 15.166016 z M 16.833984 18.5 L 24.332031 18.5 L 24.332031 20.166016 L 16.833984 20.166016 L 16.833984 18.5 z "
            transform="scale(1.5)" />
        </svg>


        <span>Več možnosti</span>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="link">
      <p id="url-text">{{url}}</p>
      <button (click)="copy()" mat-stroked-button>Kopiraj</button>
    </div>
  </div>
</div>
