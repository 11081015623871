import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AlbumViewerComponent} from './album-viewer/album-viewer.component';
import {PhotoViewComponent} from './photo-view/photo-view.component';

const routes: Routes = [
    {
        path: 'fotografije/:album',
        component: AlbumViewerComponent,
        children: [
            {
                path: ':image',
                component: PhotoViewComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlbumRoutingModule {
}
