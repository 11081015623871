/* tslint:disable:no-trailing-whitespace */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Config} from '../../../global/Config';
import {ImageMetadata} from '../../../global/models';
import {Observable} from 'rxjs';
import {AuthService} from '../../main/pages/auth/auth.service';

// noinspection JSMethodCanBeStatic
@Injectable({
    providedIn: 'root'
})
export class ArchiveManagementService {

    // todo use angular http module

    constructor(
        private http: HttpClient, private authService: AuthService) {
    }

    private readonly allowedFileTypes = ['image/png', 'image/jpeg']; // TODO add others

    public uploadImage(
        file: File,
        albumId: string,
        addWatermark: boolean = true
    ) {
        if (this.allowedFileTypes.indexOf(file.type) < 0) {
            return;
        }

        const metadata: ImageMetadata = {
            album: albumId,
            watermark: addWatermark,
            filetype: file.type,
            albumIndex: 0,
            filename: file.name
        };

        return this.authService.user.getIdToken().then(token => {

            const formdata = new FormData();
            formdata.append('payload', file, file.name);
            formdata.append('metadata', JSON.stringify(metadata));

            const headers = new HttpHeaders({
                Authorization: token
            });

            const req = new HttpRequest('POST', Config.API_UPLOAD_IMAGE, formdata, {
                reportProgress: true, headers
            });

            return this.http.request(req);
        });
    }

    public createAlbum(
        title: string,
        description: string,
        date: string,
        location: string,
        authors: string,
        category: string): Promise<Observable<any>> {

        return this.authService.user.getIdToken().then(token => {

            const formdata = new FormData();
            formdata.append('title', title);
            formdata.append('description', description);
            formdata.append('date', date);
            formdata.append('location', location);
            formdata.append('author', authors);
            formdata.append('category', category);

            const headers = new HttpHeaders({
                Authorization: token
            });

            return this.http.post(Config.API_ALBUM, formdata, {headers});
        });
    }

    public updateAlbumInformation(albumId: string, updates) {

        return this.authService.user.getIdToken().then(token => {
            const formdata = new FormData();
            formdata.append('title', updates.title);
            formdata.append('description', updates.description);
            formdata.append('album', albumId);

            const headers = new HttpHeaders({
                Authorization: token
            });

            return this.http.post(Config.API_UPDATE_ALBUM, formdata, {headers});
        });

    }

    public updateImageOrder(albumId: string, orderByDate: boolean): Promise<Observable<any>> {
        return this.authService.user.getIdToken().then(token => {
            const formdata = new FormData();
            formdata.append('album', albumId);
            formdata.append('orderByDate', String(orderByDate));
            const headers = new HttpHeaders({
                Authorization: token
            });

            return this.http.post(Config.API_UPDATE_ALBUM_IMAGE_ORDER, formdata, {headers});
        });
    }

}
