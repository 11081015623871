import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdminComponent} from './admin/admin.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {MatTabsModule} from '@angular/material/tabs';
import {MatBadgeModule} from '@angular/material/badge';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/auth.guard';


const config = {
    apiKey: 'AIzaSyDQQkGb_tLVCK4H4AXJktA1U9sVOsiU_h4',
    authDomain: 'enajsta.firebaseapp.com',
    databaseURL: 'https://enajsta.firebaseio.com',
    projectId: 'enajsta',
    storageBucket: 'enajsta.appspot.com',
    messagingSenderId: '276581524805',
    appId: '1:276581524805:web:b2e9f41e16fdcdfeec4a2c',
    measurementId: 'G-ZGZJ8JN2DX'
};

const adminRoutes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                canActivateChild: [AuthGuard],
                children: []
            }
        ]
    }
];


@NgModule({
    declarations: [
        AdminComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(adminRoutes),
        MatButtonModule,
        MatIconModule,
        MatBottomSheetModule,
        MatListModule,
        MatStepperModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatMenuModule,
        MatDividerModule,
        MatDialogModule,
        MatCardModule,
        MatProgressBarModule,
        AngularFireModule.initializeApp(config),
        AngularFirestoreModule,
        AngularFireStorageModule,
        MatTabsModule,
        MatBadgeModule,
        MatGridListModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatCheckboxModule
    ],
    exports: [RouterModule]
})
export class AdminModule {
}
