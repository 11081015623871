<div class="header">
  <button mat-icon-button routerLink="/fotografije">
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>
<div class="wrapper">
  <h1>Ustvari nov album</h1>
  <form autocomplete="off" class="form">
    <mat-form-field appearance="outline" class="album-data-field">
      <mat-label>Datum</mat-label>
      <!--suppress TypeScriptValidateTypes -->
      <input matInput [matDatepicker]="picker" autocomplete="off" (dateChange)="setDate($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" class="album-data-field">
      <mat-label>Lokacija</mat-label>
      <input type="text"
             placeholder="Lokacija"
             aria-label="Location"
             matInput
             maxlength="45"
             autocomplete="off"
             [formControl]="locationFormControl"
             [matAutocomplete]="auto"
             [(ngModel)]="location">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" class="album-data-field">
      <mat-label>Fotograf/i</mat-label>
      <input matInput maxlength="45" autocomplete="off" [(ngModel)]="author" name="author">
    </mat-form-field>
    <mat-form-field appearance="outline" class="album-data-field">
      <mat-label>Vrsta dogodka</mat-label>
      <mat-select [(ngModel)]="category" name="category">
        <mat-option *ngFor="let category of categories" [value]="category.id">
          {{category.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="hat"></div>
  <button mat-raised-button color="accent" (click)="createAlbum()">Ustvari</button>
</div>
<div class="loading" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</div>
