import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.css']
})
export class AboutComponent {

    constructor(public dialog: MatDialog) {
    }

    openMaps() {
        window.location.href = 'https://goo.gl/maps/fd9kFz3j66Ws2cP36';
    }

}
