import {DataBag, DataExtractor} from './DataExtractor';

export interface Query {
    title: string;
    date: string;
    author: string;
    location: string;
    category: string;
}

export class QueryParser {

    private dataBag: DataBag;
    private queryStringSet: string[];

    private parseField(dataBagSection): string {
        let result = '';
        for (const field of dataBagSection) {
            for (const queryPart of this.queryStringSet) {
                const formattedQueryPart = queryPart.toLowerCase().trim();
                if (field.indexOf(formattedQueryPart) > -1) {
                    result = queryPart.toLowerCase().trim();
                }
            }
        }
        return result;
    }

    public build(data) {
        const extractor = new DataExtractor(data);
        this.dataBag = extractor.extract();
    }

    public parse(queryString: string): Query {
        this.buildQueryStringSet(queryString);
        return {
            author: this.parseField(this.dataBag.authors),
            category: this.parseField(this.dataBag.categories),
            date: this.parseField(this.dataBag.dates),
            location: this.parseField(this.dataBag.locations),
            title: this.parseField(this.dataBag.titles)
        };
    }

    private buildQueryStringSet(queryString: string) {
        this.queryStringSet = [];
        queryString = queryString.replace(/\s+/g, ' ').trim().toLowerCase();
        const words = queryString.split(new RegExp(' '));
        for (let i = 0; i < words.length; i++) {
            for (let j = i; j < words.length; j++) {
                let queryFragment = '';
                for (let k = i; k <= j; k++) {
                    queryFragment += ' ' + words[k];
                }
                this.queryStringSet.push(queryFragment.substr(1));
            }
        }
        this.queryStringSet.sort((a: string, b: string) => {
            if (a.length > b.length) {
                return 1;
            } else if (a.length < b.length) {
                return -1;
            } else {
                return 0;
            }
        });
    }
}
