/* tslint:disable:no-trailing-whitespace */
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Album} from '../../model/Album';
import {ArchiveService} from '../../../archive.service';
import {GridBuilder} from '../../../image-grid/GridBuilder';
import {NavigationService, TransitionType} from '../navigation.service';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {Location} from '@angular/common';
import {AuthService} from '../../../../main/pages/auth/auth.service';

@Component({
    selector: 'app-gallery-view',
    templateUrl: './gallery-view.component.html',
    styleUrls: ['./gallery-view.component.css']
})
export class GalleryViewComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private router: Router,
                private archiveService: ArchiveService,
                private navigationService: NavigationService,
                private dialog: MatDialog,
                private location: Location,
                private authService: AuthService
    ) {
        const albumId = this.route.snapshot.paramMap.get('album');
        this.initAlbumData(albumId);
    }

    loaded = false;
    albumLoaded = false;
    albumObservable: Observable<Album>;
    album: Album;
    contentWidth = 0;
    imageMargins = window.innerWidth <= 1000 ? 1 : 3;
    gridBuilder = new GridBuilder();
    editor = this.authService.isLoggedIn;


    ngOnInit(): void {
        this.screenSizeListener();
        this.scrollListener();
    }


    private initAlbumData(id: string) {
        this.albumObservable = new Observable<Album>(subscriber => {
            if (this.archiveService.isArchiveLoaded()) {
                this.album = this.archiveService.archive.getAlbum(id);
                this.albumLoaded = true;
                subscriber.next(this.album);
                this.getEntries(id);
            } else {
                this.archiveService.loadAlbum(id).subscribe(data => {
                    this.album = new Album(data);
                    this.albumLoaded = true;
                    subscriber.next(this.album);
                    this.getEntries(id);
                });
            }
        });
    }

    screenSizeListener() {
        const update = () => {
            this.updateContentWidth();
            this.updateImageMargins();
            this.gridBuilder.computeColumnCount();
        };
        update();
        window.addEventListener('resize', update);
    }

    private scrollListener() {
        const wrapper = document.getElementById('wrapper');
        const update = () => {
            this.gridBuilder.update(wrapper, this.contentWidth, this.imageMargins);
        };
        wrapper.addEventListener('scroll', update);
    }

    updateContentWidth() {
        this.contentWidth = document.getElementById('content').getBoundingClientRect().width;
        this.gridBuilder.displayWidth = this.contentWidth;
    }

    private updateImageMargins() {
        const newValue = window.innerWidth <= 1000 ? 1 : 3;
        const updated = newValue !== this.imageMargins;
        if (updated) {
            this.imageMargins = newValue;
        }
        this.gridBuilder.imageMargins = this.imageMargins;
    }

    navigateToImage(id: string, image) {
        this.navigationService.transition = {
            TransitionType: TransitionType.Gallery,
            fromImage: image
        };
        this.router.navigate([id], {relativeTo: this.route});
    }

    getEntries(id: string): void {
        this.archiveService.getAlbumEntries(id, (images) => {
            this.navigationService.initImageCollection(images);
            images.orderByDate = this.album.orderByDate;
            this.gridBuilder.images = images;
            this.gridBuilder.build();
            this.loaded = true;
        });
    }

}
