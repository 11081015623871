import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArchiveManagementRoutingModule } from './archive-management-routing.module';
import { NewAlbumComponent } from './create-album/new-album.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import { DropFilesSurfaceComponent } from './drop-files-surface/drop-files-surface.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { EditAlbumComponent } from './edit-album/edit-album.component';
import { ConfirmationDialogComponent } from './edit-album/confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';

@NgModule({
  declarations: [NewAlbumComponent, DropFilesSurfaceComponent, EditAlbumComponent, ConfirmationDialogComponent],
    imports: [
        CommonModule,
        ArchiveManagementRoutingModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatSlideToggleModule,
        FormsModule,
        MatDialogModule,
        MatProgressBarModule,
        MatMenuModule,
        MatListModule
    ]
})
export class ArchiveManagementModule { }
