import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AlbumRoutingModule} from './album-routing.module';
import {AlbumAppbarComponent} from './gallery-view/appbar/album-appbar.component';
import {AlbumViewerComponent} from './album-viewer/album-viewer.component';
import {PhotoViewComponent} from './photo-view/photo-view.component';
import {GalleryViewComponent} from './gallery-view/gallery-view.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import { DownloadDialogComponent } from './photo-view/image-download-dialog/download-dialog.component';
import {MatRippleModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';



@NgModule({
    declarations: [
        GalleryViewComponent,
        AlbumAppbarComponent,
        AlbumViewerComponent,
        PhotoViewComponent,
        DownloadDialogComponent
    ],
    exports: [
        AlbumAppbarComponent
    ],
    imports: [
        CommonModule,
        AlbumRoutingModule,
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatRippleModule,
        MatDividerModule,
        MatRadioModule,
        FormsModule,
        MatInputModule
    ]
})
export class AlbumModule {
}
