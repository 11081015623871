<div id="wrapper" class="wrapper">

  <h1 class="title unselectable">Prijava</h1>
  <div class="login-container">

    <form *ngIf="!authService.isLoggedIn" class="login-form" (submit)="login()">
      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input [(ngModel)]="username" matInput [formControl]="emailFormControl" placeholder="xisnoub@gmail.com"
               (keydown)="moveFocus($event)">
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Vnesi validen e-mail
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          <strong>Potreben </strong>je Email
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="!authService.isLoggedIn">
        <mat-label>Geslo</mat-label>
        <input id="password" [(ngModel)]="password" type="password" matInput [formControl]="passworFormControl"
               (keydown)="pressEnter($event)">
        <mat-error *ngIf="passworFormControl.hasError('email') && !passworFormControl.hasError('required')">
          Vnesi validno geslo
        </mat-error>
        <mat-error *ngIf="passworFormControl.hasError('required')">
          <strong>Potrebno </strong>je geslo
        </mat-error>
      </mat-form-field>


      <p>{{message}}</p>
      <p class="buttons">
        <button mat-flat-button (click)="back()">Nazaj</button>
        <button type="submit" mat-raised-button color="accent">Prijava</button>
      </p>

    </form>


    <form *ngIf="authService.isLoggedIn" class="login-form" (submit)="logout()">
      <p>{{setMessage()}}</p>
      <p class="buttons">
        <button mat-flat-button (click)="back()">Nazaj</button>
        <button type="submit" mat-raised-button color="accent">Odjava</button>
      </p>

    </form>


  </div>

</div>
<mat-progress-spinner class="progress" color="accent" *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
