<div>
  <div class="contact-from" id="activeForm">

    <mat-progress-bar *ngIf="submitted" class="progress" mode="indeterminate"></mat-progress-bar>

    <form id="ngForm" (ngSubmit)="onSubmit()">

      <div class="header">
        <h1 class="mat-headline">Pišite nam ...</h1>
        <button class="send-button" type="submit" color="accent" mat-stroked-button aria-label="send button">
          POŠLJI
        </button>
      </div>


      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Vaš e-naslov</mat-label>
        <input type="text" class="form-control" id="from" required [(ngModel)]="mail.from" name="from" matInput
               [formControl]="email" required>
        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>Vaše ime</mat-label>
        <input type="text" class="form-control" id="subject" required [(ngModel)]="mail.subject" name="subject"
               matInput [formControl]="name" required>
      </mat-form-field>

      <p class="aaft">Kako vam lahko pomagamo?</p>

      <mat-form-field appearance="outline" class="input-field">
                <textarea type="text" class="form-control" id="message" required [(ngModel)]="mail.message"
                          name="message" matInput [formControl]="body" required></textarea>
        <mat-error *ngIf="body.invalid">{{getErrorMessageBody()}}</mat-error>
      </mat-form-field>


    </form>
  </div>
</div>
