export interface Category {
    id: number;
    viewValue: string;
}

export const eventCategories: Category[] = [
    {id: 0, viewValue: 'Tabor'},
    {id: 1, viewValue: 'Pomladovanje'},
    {id: 2, viewValue: 'Jesenovanje'},
    {id: 3, viewValue: 'Kostanjev piknik'},
    {id: 4, viewValue: 'Dedkomrazovanje'},
    {id: 5, viewValue: 'Vodova akcija'},
    {id: 6, viewValue: 'Motivacijski vikend'},
    {id: 7, viewValue: 'Dogodek'},
];
