<app-appbar></app-appbar>

<div class="wrapper">
  <h1 class="title mat-display-3">Kontakt</h1>
  <div class="container">
    <div class="h14">
      <div class="h15">
        <div class="text">
          <h1 class="subtitle unselectable">Kako do nas?</h1>
          <h3 class="mat-h3">Vprašaj nas karkoli o našem društvu in taborništvu nasploh! Lahko nas povprašaš,
            kdaj
            lahko prideš pogledat, kaj taborniki počnemo, nam sporočiš spremembo podatkov, se pritožiš, nas
            pohvališ ali svetuješ. Sporočila preverimo večkrat na teden, a se lahko zgodi, da bo trajalo
            nekaj
            dni, preden bomo lahko odgovorili, saj bomo poskusili vprašanje nasloviti na primerno osebo.
            Priporočamo, da vprašanja v zvezi z vodovim programom nasloviš kar na vodnika.</h3>
          <h3 class="mat-h3 tt">Starešina <strong>Nina Medved</strong> - <a
            href="mailto:ninamedved.nm@gmail.com">ninamedved.nm@gmail.com</a></h3>
          <h3 class="mat-h3 tt">Načelnik <strong>Mitja Brglez</strong> - <a
            href="mailto:brglez.mitja@gmail.com">brglez.mitja@gmail.com</a></h3>
        </div>
        <div class="vizitka">
          <h1 class="subtitle unselectable">Vizitka</h1>
          <h3 class="mat-h3"><strong>Društvo tabornikov rod XI.SNOUB Miloša Zidanška</strong><br/>
            <strong>Verstovškova ulica 4</strong> 2000 Maribor <br/>Uradne ure vsak petek med <strong>19.30
              in
              20.30</strong> uro <br/>
            Matična številka: <strong>5161452 </strong><br/> Davčna številka: <strong>33997519</strong>
            (nismo
            zavezanci za plačilo DDV)
            <br/> Št.
            transakcijskega računa: <strong>SI56 6100 0000 7237 905</strong>
          </h3>
        </div>
      </div>


      <div class="form-container" id="form">
        <ng-template formHost></ng-template>
        <!-- <app-contact-form (sent)="onSent($event)"></app-contact-form> -->
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
