/* tslint:disable:variable-name no-trailing-whitespace */

import {ImageGrid} from './ImageGrid';
import {ImageCollection} from '../photos/model/ImageCollection';
import {ImageRow} from './ImageRow';
import {ImageInstance} from './ImageInstance';
import {ImageProperties} from './ImageProperties';

interface RowRequest {
    rowIndex: number;
    rowSize: number;
    firstImageIndex: number;
    onLoad: (imageRow: ImageRow) => void;
    onError: () => void;
}

export class GridBuilder {

    get grid(): ImageGrid {
        return this._grid;
    }

    get numberOfColumns(): number {
        return this._numberOfColumns;
    }

    set numberOfColumns(value: number) {
        this._numberOfColumns = value;
    }

    get images(): ImageCollection {
        return this._images;
    }

    set images(value: ImageCollection) {
        this._images = value;
    }

    private _grid = new ImageGrid([]);
    private _numberOfColumns = 3;
    private _images: ImageCollection;
    private rowCount = 0;
    private lastRowSize = 0;
    private loadingRowsCount = 0;


    private _imageMargins: number;
    private _displayWidth: number;


    set imageMargins(value: number) {
        this._imageMargins = value;
    }

    set displayWidth(value: number) {
        this._displayWidth = value;
    }

    private fillImages = 0;

    public append() {
        for (let i = 0; i < 4; i++) {
            this.addBottomRow();
        }
    }

    build() {
        if (!this._images) {
            return;
        }
        const numberOfImages = this._images.size();
        this.rowCount = Math.floor(numberOfImages / this.numberOfColumns);
        this.lastRowSize = numberOfImages % this.numberOfColumns;
        if (this.lastRowSize > 0) {
            this.rowCount++;
        }
        this.loadingRowsCount = 0;
        this.append();
    }

    private addBottomRow() {
        const row = this.grid.rows.length + this.loadingRowsCount;
        let rowSize = (row < this.rowCount - 1 || this.lastRowSize === 0) ? this.numberOfColumns : this.lastRowSize;
        const firstRowImageIndex = row * this.numberOfColumns;

        const expansionRequest = () => {
            if (this._images.size() < firstRowImageIndex + ++rowSize) {
                return false;
            }
            requestExpandedRow();
            return true;
        };

        const requestExpandedRow = () => {
            this.requestRow(row, rowSize, firstRowImageIndex, expansionRequest);
            this.fillImages++;
        };

        this.requestRow(row, rowSize, firstRowImageIndex, expansionRequest);
    }

    /**
     *
     * @param row
     * @param rowSize
     * @param firstRowImageIndex
     * @param onExpansionRequest return false if row shall be loaded despite request
     * @private
     */
    private requestRow(
        row: number,
        rowSize: number,
        firstRowImageIndex: number,
        onExpansionRequest: () => boolean
    ) {
        const rowRequest: RowRequest = {
            rowIndex: row,
            rowSize,
            firstImageIndex: firstRowImageIndex,
            onLoad: (imageRow: ImageRow) => {
                this.loadingRowsCount--;
                const actualRowWidth = imageRow.getRowWidth(this._displayWidth, this._imageMargins);

                if (Math.ceil(actualRowWidth) < Math.ceil(this._displayWidth)) {
                    if (onExpansionRequest()) {
                        return;
                    }
                }
                this.grid.addRowAt(imageRow, row);
            },
            onError: () => {
                this.loadingRowsCount--;
            }
        };
        this.loadRow(rowRequest);
        this.loadingRowsCount++;
    }

    private loadRow(request: RowRequest) {
        const loadedImages = new Array<ImageInstance>(request.rowSize);
        let loadedImagesCount = 0;
        for (let i = 0; i < request.rowSize; i++) {
            const imageIndex = request.firstImageIndex + i;
            try {
                const imageProperties = this.images.getImage(imageIndex);
                this.loadImage(imageProperties, (img => {
                    loadedImages[i] = img;
                    loadedImagesCount++;
                    if (loadedImagesCount === request.rowSize) {
                        request.onLoad(new ImageRow(loadedImages, request.rowIndex));
                    }
                }), () => {
                    request.onError();
                    return;
                });
            } catch (e) {
                break;
            }
        }
    }

    loadImage(
        imageProperties: ImageProperties,
        onLoad: (imageInstance: ImageInstance) => void,
        onError: () => void = () => {
        }
    ) {
        const img = new Image();
        img.onload = () => {
            const image = new ImageInstance(imageProperties.id, img);
            onLoad(image);
        };
        img.onerror = onError;
        img.src = imageProperties.getUrl();
    }

    rebuild() {
        this.grid.clear();
        this.build();
    }

    rebuildFrom(rowIndex: number) {
        this.grid.clearFrom(rowIndex);
        this.build();
    }

    removeItems(ids: string[]) {
        let changedAt = this.grid.rows.length - 1;
        for (const id of ids) {
            for (const row of this.grid.rows) {
                for (const image of row.images) {
                    if (image.id === id) {
                        if (row.rowIndex < changedAt) {
                            changedAt = row.rowIndex;
                        }
                    }
                }
            }
            this._images.removeImage(id);
        }
        this.rebuildFrom(changedAt);
    }

    update(wrapper: HTMLElement, displayWidth: number, imageMargins: number) {
        const rowHeight = this.grid.rows[0].getRowHeight(displayWidth, imageMargins);
        const bottomOffset = wrapper.scrollHeight - wrapper.offsetHeight - wrapper.scrollTop;
        const notOverLoading = wrapper.offsetHeight > this.loadingRowsCount * rowHeight;
        if (bottomOffset < 600 && notOverLoading) {
            this.addBottomRow();
        }
    }

    computeColumnCount() {
        const width = window.innerWidth;
        let columns;
        if (width > 1000) {
            columns = 3;
        } else {
            columns = 2;
        }

        if (this.numberOfColumns !== columns) {
            this.numberOfColumns = columns;
            this.rebuild();
        }
    }
}
