import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {UntypedFormControl, Validators, FormGroupDirective, NgForm} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent {

    constructor(public authService: AuthService, public router: Router, private _snackBar: MatSnackBar) {
        this.setMessage();
    }

    message: string;
    loading = false;
    username: string;
    password: string;

    emailFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.email,
    ]);

    passworFormControl = new UntypedFormControl('', [
        Validators.required,
    ]);

    setMessage() {
        this.message = (this.authService.isLoggedIn ? this.authService.user.email : 'Odjavljen');
        return this.message;
    }

    login() {
        document.getElementById('wrapper').classList.add('loading');
        this.message = 'Prijavljanje ...';
        this.loading = true;
        this.authService.login(this.username, this.password)
            .then(result => {
                this.router.navigate(['/']);
                this._snackBar.open('Prijavljen kot ' + result.user.email, null, {
                    duration: 2000,
                });
            }).catch(error => {
            this._snackBar.open(/*error*/ 'Nekaj ne bo vredu 😢', null, {
                duration: 2000,
            });
        }).finally(() => {
            this.loading = false;
            document.getElementById('wrapper').classList.remove('loading');
            this.setMessage();
        });
    }

    back() {
        const redirectUrl = '/';
        this.router.navigate([redirectUrl]);
    }

    admin() {
        this.router.navigate(['/admin']);
    }

    logout() {
        this.authService.logout().then(() => {
            this.setMessage();
        });
    }


    pressEnter(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.login();
        }
    }

    moveFocus(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById('password').focus();

        }
    }

}
