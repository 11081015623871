<app-appbar></app-appbar>
<app-header></app-header>
<app-tabs></app-tabs>
<app-about></app-about>
<app-tax-form></app-tax-form>
<div class="d1">
  <app-join-us></app-join-us>
  <app-contact-form></app-contact-form>
</div>
<app-footer></app-footer>
