/* tslint:disable:no-trailing-whitespace */
import {ImageInstance} from './ImageInstance';

// noinspection JSMethodCanBeStatic
export class ImageRow {

    private readonly _images: Array<ImageInstance>;
    private readonly _rowIndex: number;


    constructor(images: Array<ImageInstance>, rowIndex: number) {
        this._images = images;
        this._rowIndex = rowIndex;
    }


    get images(): Array<ImageInstance> {
        return this._images;
    }

    get rowIndex(): number {
        return this._rowIndex;
    }

    public getRowHeight(rowDisplayWidth: number, imageMargins: number = 0) {

        if (this._images.length < 1) {
            throw new Error('No Images');
        }

        const whiteSpace = 2 * imageMargins * this._images.length;
        rowDisplayWidth -= whiteSpace;

        const defaultHeight = this._images[0].img.height;
        let defaultWidth = 0;
        for (const image of this._images) {
            const width = image.img.width;
            defaultWidth += width;
        }

        const fraction = (1.0 / rowDisplayWidth) * defaultWidth;

        const factor = 1.0 / fraction;

        const height = defaultHeight * factor;

        let calculatedWidth = 0.0;
        for (const image of this.images) {
            calculatedWidth += image.img.width * factor;
        }

        const maxHeight = 350 + (this.images.length - 1) * 100;

        return ((height < maxHeight) ? height : maxHeight);

    }

    public getRowWidth(rowDisplayWidth: number, imageMargins: number) {

        const height = this.getRowHeight(rowDisplayWidth, imageMargins);

        let width = 0;
        for (const image of this._images) {
            const htmlImage = image.img;
            const imageWidth = this.getImageDisplayWidth(height, htmlImage.height, htmlImage.width);
            width += imageWidth;
        }
        width += this.images.length * imageMargins * 2;
        return width;
    }

    private getImageDisplayWidth(displayHeight: number, fullImageHeight: number, fullImageWidth: number) {
        const scale = displayHeight / fullImageHeight;
        return scale * fullImageWidth;
    }
}
