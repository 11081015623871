import {Config} from '../../../global/Config';

export class ImageProperties {

    private _id: string;
    private readonly _album: string;
    private readonly _date: number;

    constructor(id: string, album: string, date: number) {
        this._id = id;
        this._album = album;
        this._date = date;
    }


    get id(): string {
        return this._id;
    }

    get album(): string {
        return this._album;
    }

    get date(): number {
        return this._date;
    }

    set id(value: string) {
        this._id = value;
    }

    public getUrl() {
        return Config.API_IMAGE +
            this._album + '/' +
            this.id + '?size=small';
    }

}
