import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AppbarService} from '../../services/appbar.service';
import {ApplicationStateService} from '../../services/application-state-service.service';

export interface DialogData {
    dialog: boolean;
}

@Component({
    selector: 'app-kroj',
    templateUrl: './kroj.component.html',
    styleUrls: ['./kroj.component.css']
})
export class KrojComponent {

    constructor(public dialog: MatDialog) {
    }


    openDialog(dialog: boolean): void {
        const dialogRef = this.dialog.open(OrderDialog, {
            data: {dialog}

        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }
}

@Component({
    selector: 'order-dialog',
    templateUrl: 'order-dialog.html',

})
export class OrderDialog implements OnInit {

    ngOnInit(): void {
    }

    constructor(public dialogRef: MatDialogRef<OrderDialog>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
