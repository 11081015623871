<app-appbar></app-appbar>


<div class="wrapper">

    <h1 class="title mat-display-3">Uniforma</h1>

    <h1 class="mat-headline unselectable">Kroj</h1>
    <h3 class="mat-h3">
        Taborniško unifromo imenujemo kroj. To je častno oblačilo, ki ga nosimo med slovesnimi priložnostmi in dnevnimi
        zbori na taborniških akcijah. Za tabornike ima poseben pomen. Našemu ustanovitelju, Badnu Powellu, se je zdelo
        posebno pomembno, da pri tabornikih ne šteje, koliko denarja imajo starši članov. Pri tabornikih smo vsi
        enakovredni in kadar nosimo enako uniformo, je to še posebej vidno.
    </h3>
    <h1 class="mat-headline unselectable">Naroči uniformo</h1>
    <h3 class="mat-h3">
        Taborniški kroj lahko naročiš preko spleta iz taborniške trgovine (trenutno žal ne deluje). Če se mudiš po
        Ljubljani, jo lahko obiščeš tudi v živo na Einspielerjevi 6. Da ti olajšamo delo, nekajkrat na leto zberemo
        naročila in kroje naročimo skupaj. Kontaktiraj <a routerLink="/vodi">svojega vodnika</a> za več informacij.
    </h3>

    <h1 class="mat-headline unselectable">Rutica</h1>
    <h3 class="mat-h3">Taborniško <a href="https://drive.google.com/file/d/1_Qurq-dhQ8zfyMi7NYw2vxGHC9_R6qO_/view?usp=sharing">rutico</a> in našitke si boš prislužil čez leto in prejel na večdnevnih akcijah.


    </h3>
    <h1 class="mat-headline unselectable">Kako urediti kroj</h1>
    <h3 class="mat-h3">Če si si že prislužil nekaj veščin in si v dvomih, kako jih prav prišiti na svoj kroj, najdeš
        podrobna navodila na <a href="https://www.taborniki.si/taborniski-kroj/" target="_blank">tej strani</a>.
    </h3>

    <h1 class="title mat-display-3">Rodova kolekcija (v pripravi)</h1>

    <h3 class="mat-h3">Ob taborniški uniformi, imamo tudi rodovo majico in jopico, ki ju nosimo na vodovih sestankih
        in na tekmovanjih. Ker smo sami odgovorni za dobavo oblačil, jih ni možno naročiti čez vso leto. Obvestili vas
        bomo ob naslednjem možnem terminu. Do takrat lahko zabeležite svoje naročilo spodaj:
    </h3>


    <div class="shop">
        <div class="container" (click)="openDialog(false)">
            <!-- <img src="../../assets/images/shop/majica.webp" alt="Fotografija majice" class="image undraggable"> -->

            <picture>
                <source srcset="../../../../../assets/slike/webp/shop/majica.webp" type="image/webp">
                <img class="image undraggable" src="../../../../../assets/slike/JPEG/shop/majica.jpg">
            </picture>

            <div class="middle undraggable">
                <h1 class="mat-headline unselectable white">Naroči majico</h1>
            </div>
            <h1 class="mat-headline unselectable">Naroči</h1>

        </div>

        <div class="container" (click)="openDialog(true)">
            <picture>
                <source srcset="../../../../../assets/slike/webp/shop/hoodie.webp" type="image/webp">
                <img class="image undraggable" src="../../../../../assets/slike/JPEG/shop/hoodie.jpg">
            </picture>            <div class="middle undraggable">
                <h1 class="mat-headline unselectable white">Naroči jopico</h1>
            </div>
            <h1 class="mat-headline unselectable">Naroči</h1>

        </div>

    </div>



    <!-- <div class="buttons">
        <button class="big-button" mat-flat-button color="primary" (click)="openDialog(false)">Naroči majico</button>
        <button class="big-button" mat-flat-button color="primary" (click)="openDialog(true)">Naroči jopico</button>
    </div> -->



    <!-- <div class="forms">
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSffBBa4gkV_d23Z24eatU2Pgmv3V58BWbsD_O7RW7r8K7EnsA/viewform?embedded=true"
            width="640" height="800" frameborder="0" marginheight="0" marginwidth="0">Nalaganje …</iframe>
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfZVKEBcizV9BfAyzxfWkXBGOIhy3Jkbg_N80rl84OvUrNWCg/viewform?embedded=true"
            width="640" height="800" frameborder="0" marginheight="0" marginwidth="0">Nalaganje …</iframe>
    </div> -->



</div>
<app-footer></app-footer>
