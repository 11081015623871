/* tslint:disable:no-trailing-whitespace */
// noinspection JSMethodCanBeStatic

import {TransitionType} from './navigation.service';

interface ImageProperties {
    offsetTop: number;
    offsetLeft: number;
    width: number;
    height: number;
}

interface Animation {
    name: string;
    duration?: number;
    timingFunction?: string;
    fillMode?: string;
}

export class AnimationGenerator {

    galleryTransition(image: HTMLElement): any {
        const animationProperties = this.buildAnimation({
                name: 'toFullScreen',
                duration: 300,
                timingFunction: 'ease-in-out'
            }
        );
        const position = this.imagePosition(image);
        return Object.assign(animationProperties, position);
    }

    nextTransition(image: HTMLImageElement) {
        const animationProperties = this.buildAnimation({
                name: 'fromRight'
            }
        );
        const position = this.imagePosition(image);
        return Object.assign(animationProperties, position);
    }

    previousTransition(image: HTMLImageElement) {
        const animationProperties = this.buildAnimation({
                name: 'fromLeft'
            }
        );
        const position = this.imagePosition(image);
        return Object.assign(animationProperties, position);
    }

    fadeOutTransitionNext() {
        return this.buildAnimation({
            name: 'fadeOutRight',
            fillMode: 'backwards'
        });
    }

    fadeOutTransitionPrevious() {
        return this.buildAnimation({
            name: 'fadeOutLeft',
            fillMode: 'backwards'
        });
    }

    private buildAnimation(
        animationProperties: Animation
    ) {
        if (!animationProperties.duration) {
            animationProperties.duration = 300;
        }
        if (!animationProperties.timingFunction) {
            animationProperties.timingFunction = 'ease';
        }
        if (!animationProperties.fillMode) {
            animationProperties.fillMode = 'forwards';
        }

        return {
            'animation-name': animationProperties.name,
            'animation-duration': animationProperties.duration + 'ms',
            'animation-timing-function': animationProperties.timingFunction,
            'animation-fill-mode': animationProperties.fillMode
        };
    }

    private imagePosition(image: HTMLElement) {
        const properties = this.getImageProperties(image);
        return {
            width: `${properties.width}px`,
            height: `${properties.height}px`,
            top: `${properties.offsetTop}px`,
            left: `${properties.offsetLeft}px`
        };
    }

    private getImageProperties(image: HTMLElement): ImageProperties {
        const rect = image.getBoundingClientRect();
        return {
            height: rect.height,
            offsetLeft: rect.left,
            offsetTop: rect.top,
            width: rect.width
        };
    }
}
