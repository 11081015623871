<app-appbar></app-appbar>


<div class="wrapper">

    <h1 class="title mat-display-3">Pravno obvestilo</h1>

    <h1 class="mat-headline unselectable">Politika uporabe piškotkov</h1>
    <h3 class="mat-h3">
        Spletna stran uporablja izključno piškotke, ki so nujno potrebni za delovanje spletnega mesta. Brez teh
        piškotkov spletno mesto ne bi delovalo pravilno.
    </h3>
    <h1 class="mat-headline unselectable">Kaj so piškotki?</h1>
    <h3 class="mat-h3">
        Piškotki so male besedilne datoteke, ki jih večina spletnih mest shrani v naprave uporabnikov s katerimi
        dostopajo do interneta z namenom prepoznavanja posameznih naprav, ki so jih uporabniki uporabili pri dostopu.
        Njihovo shranjevanje je pod popolnim nadzorom brskalnika, ki ga uporablja uporabnik – ta lahko shranjevanje
        piškotkov po želji omeji ali onemogoči.
    </h3>
    <h1 class="mat-headline unselectable">Zakaj so piškotki potrebni?</h1>
    <h3 class="mat-h3">
        Piškotki so temeljnega pomena za zagotavljanje uporabniku prijaznih spletnih storitev. Najpogostejše funkcije
        eposlovanja ne bi bile mogoče brez piškotkov. Interakcija med spletnim uporabnikom in spletno stranjo je s
        pomočjo piškotkov hitrejša in enostavnejša. Z njihovo pomočjo si spletna stran zapomni posameznikove preference
        in izkušnje, s tem je prihranjen čas, brskanje po spletnih straneh pa je s tem bolj učinkovito in prijetno.
    </h3>
    <h1 class="mat-headline unselectable">Zakaj spletne strani uporabljajo piškotke?</h1>
    <h3 class="mat-h3">
        Piškotke uporablja večina spletnih strani, so priročno sredstvo za ohranjanje sveže in primerne vsebine, ki je v
        skladu z interesi in preferencami posameznega spletnega uporabnika. Razlogov za uporabo piškotkov je tako več,
        kot prvi gotovo njihova lastnost shranjevanja podatkov o stanju posamezne spletne strani (podrobnosti o
        prilagoditvah posamezne spletne strani), pomagajo pri izvajanju raznih spletnih storitev,
        pomagajo pri zbiranju raznih statistik, o navadah spletnega uporabnika – saj se le z njimi lahko spremlja
        pogostost obiskovanja določene spletne strani. Podjetja lahko s pomočjo piškotkov ocenijo učinkovitost zasnove
        svojih spletnih strani, kot tudi ustreznost vrste in števila oglasov, ki jih ponujajo uporabnikom na svojih
        spletnih straneh.
    </h3>

    <h1 class="mat-headline unselectable">Shranjevanje podatkov</h1>
    <h3 class="mat-h3">
        XI. SNOUB ne shranjuje nobenih podatkov uporabnikov spletne strani.
    </h3>

</div>

<app-footer></app-footer>
