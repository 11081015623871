/* tslint:disable:no-trailing-whitespace */
import {Component, Input, OnInit} from '@angular/core';
import {ShareDialogComponent} from '../../../share-dialog/share-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Album} from '../../../model/Album';
import {Observable} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {Config} from '../../../../../../global/Config';

// noinspection JSMethodCanBeStatic
@Component({
    selector: 'app-appbar',
    templateUrl: './album-appbar.component.html',
    styleUrls: ['./album-appbar.component.css']
})
export class AlbumAppbarComponent implements OnInit {
    @Input() albumObservable: Observable<Album>;
    album: Album;

    @Input() editor = false;

    showTitleInAppBar = false;

    constructor(
        public dialog: MatDialog,
        private titleService: Title
    ) {
    }

    ngOnInit(): void {
        this.albumObservable.subscribe(album => {
            this.album = album;
            this.showTitleOnScroll();
            this.titleService.setTitle(album.getTitle() + ' | XI. SNOUB');
        });
    }

    openEditor() {
        if (this.album !== undefined) {
            window.location.href = Config.BASE_WEB_CONTENT_URL + 'archive/edit/' + this.album.getId();
            // FIXME: Should be using Router, but it wont use proper router-outlet so fuck it.
        }
    }

    openShareDialog(): void {
        this.dialog.open(ShareDialogComponent, {
            width: '450px',
            data: {title: 'Deli album', album: this.album.getId(), image: ''},
            panelClass: 'basic-dialog-container',
            autoFocus: false
        });
    }

    showTitleOnScroll() {
        const content = document.getElementById('wrapper');
        const update = () => {
            const mainTitle = document.getElementById('main-title');
            const position = mainTitle.getBoundingClientRect().top;
            this.showTitleInAppBar = position < 70;
        };
        content.addEventListener('scroll', update);

    }
}
