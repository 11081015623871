<app-appbar></app-appbar>

<div class="wrapper">
    <h1 class="title mat-display-3">Taborni prostor</h1>



    <!-- <img  src="../../assets/images/about/8.webp" alt="slika tabora"> -->

    <picture>
        <source srcset="../../../../../assets/slike/webp/about/8.webp" type="image/webp">
        <img class="image_1" src="../../../../../assets/slike/JPEG/about/8.jpg">
    </picture>



    <h3 class="mat-h3">
        Skrit na razglednem pobočju Menine planine in le 15 minut hoje iz Gornjega Grada se nahaja naš taborni prostor.
        Ponaša se z dvema urejenima jasama, ki skupno sprejmeta kar 80 udeležencev. Na zgornji, ki je večja, stoji v
        zavetju dreves lesena hiška. V njej so: kuhinja, spalnica za 2 osebi, shramba za živila, skladišče za opremo in
        hodna streha kot dodaten skladiščni prostor. Zraven hiške je tudi urejena umivalnica. Hladna in bistra gorska
        voda prihaja iz zajetja, ki se nahaja v gozdu nad tabornim prostorom. Stranišče je nadtabornim prostorom v
        gozdu: eno stranišče ima straniščno školjko, drugo je »čučkalica«.
    </h3>



    <h3 class="mat-h3">
        Spodnja jasa, ki je bolj obdarjena s soncem, se lahko uporablja kot igrišče ali kot dodaten prostor za šotore.
        Za
        njo se razprostira čudovit razgled na Lepenatko in Rogatec. Le 10 minut hoje nas loči od osvežujočega skoka v
        gorsko rečico Dreto po vzponu na eno številnih planin, ki jih premore Zgronja Savinjska dolina.
    </h3>

    <img class="undraggable" src="/src/assets/slike/JPEG/about/10.jpg" alt="">
    <img class="undraggable" src="/src/assets/slike/JPEG/about/13.jpg" alt="">
    <br />
    <img class="undraggable" src="/src/assets/slike/JPEG/about/11.jpg" alt="">
    <img class="undraggable" src="/src/assets/slike/JPEG/about/12.jpg" alt="">


    <h1 class="mat-headline unselectable">Za več informacij o najemu tabornega prostora kontaktiraj: </h1>
    <h3 class="mat-h3">
        <a href="mailto:jernej.bobanec@gmail.com">jernej.bobanec@gmail.com</a>
        <br />
        <a href="tel:+41 502 027">041 502 027</a>
        <br />
        Poizvedbo lahko pošlješ tudi preko kontaktnega obrazca.
    </h3>

</div>
<app-footer></app-footer>
