import {Query, QueryParser} from './QueryParser';
import {ArchiveIndex, Entry} from './ArchiveIndex';


export interface Result {
    id: string;
    score: number;
}

// noinspection JSMethodCanBeStatic
export class ArchiveSearchEngine {

    private readonly queryParser = new QueryParser();
    private readonly archiveIndex = new ArchiveIndex();

    public index(archiveData: any) {
        this.queryParser.build(archiveData);
        this.archiveIndex.build(archiveData);
    }

    public search(queryString: string): Array<Result> {
        const results: Result[] = [];
        const query = this.queryParser.parse(queryString);
        for (const entry of this.archiveIndex.index.entries) {
            const score = this.matches(query, entry.entry);
            if (score) {
                results.push({
                    id: entry.id,
                    score
                });
            }
        }
        return results;
    }

    private matches(query: Query, entry: Entry): number {
        return +this.contains(entry.title, query.title) +
            +this.contains(entry.category, query.category) +
            +this.contains(entry.location, query.location) +
            +this.contains(entry.date, query.date) +
            +this.contains(entry.author, query.author);
    }

    private contains(string: string, substring: string): boolean {
        return substring !== '' && string.toLowerCase().indexOf(substring.toLowerCase().trim()) > -1;
    }
}
