import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-album-viewer',
    templateUrl: './album-viewer.component.html',
    styleUrls: ['./album-viewer.component.css']
})
export class AlbumViewerComponent {


}
