import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-tax-form',
  templateUrl: './tax-form.component.html',
  styleUrls: ['./tax-form.component.css']
})
export class TaxFormComponent implements OnInit {

  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  more() {
    const win = window.open('https://firebasestorage.googleapis.com/v0/b/enajsta.appspot.com/o/assets%2FDohodnina2018_XISNOUB.pdf?alt=media&token=2595b3c4-7aae-45a2-bbe4-b67f2a494bff', '_blank');
    win.focus();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TaxFormDialog, {

    });

    dialogRef.afterClosed().subscribe(result => {
      // Neki hvala za pomoc kurac
      this._snackBar.open('❤️ Hvala za pomoč!', null, {
        duration: 2000,
      });
    });
  }
}

@Component({
  selector: 'tax-form-dialog',
  templateUrl: 'tax-form-dialog.html',
})
export class TaxFormDialog implements OnInit {

  ngOnInit(): void {
  }

  constructor(public dialogRef: MatDialogRef<TaxFormDialog>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
