/* tslint:disable:variable-name no-trailing-whitespace */
import {Injectable} from '@angular/core';
import {Archive} from './photos/model/Archive';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from '../../global/Config';
import {ImageCollection} from './photos/model/ImageCollection';
import {Observable} from 'rxjs';
import {AuthService} from '../main/pages/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class ArchiveService {

    constructor(
        private http: HttpClient,
        private authService: AuthService) {
    }

    private _archive: Archive;

    get archive(): Archive {
        return this._archive;
    }

    set archive(value: Archive) {
        this._archive = value;
    }

    public isArchiveLoaded(): boolean {
        return typeof this.archive !== 'undefined';
    }

    public loadArchive() {
        return new Observable(subscriber => {
            this.http.get(Config.API_ARCHIVE_ENTRIES
            ).subscribe((data) => {
                this._archive = new Archive(data);
                subscriber.next(this.archive);
            });
        });
    }

    public loadAlbum(id: string): Observable<any> {
        return this.http.get(Config.API_ALBUM_INFO + id);
    }

    public getAlbumEntries(albumId: string, onDone: (images) => void) {
        this.http.get(Config.API_ALBUM_ENTRIES + albumId).subscribe((data) => {
            onDone(new ImageCollection(data, albumId));
        });
    }

    public deleteImage(albumId: string, imageId: string): Promise<Observable<any>> {
        return this.authService.user.getIdToken().then(token => {
            const formData = new FormData();
            formData.append('image', imageId);
            formData.append('album', albumId);
            const headers = new HttpHeaders({
                Authorization: token
            });
            return this.http.post(Config.API_DELETE_IMAGE, formData, {headers});
        });
    }

    public deleteAlbum(albumId: string): Promise<Observable<any>> {
        return this.authService.user.getIdToken().then(token => {
            const formdata = new FormData();
            formdata.append('album', albumId);
            const headers = new HttpHeaders({
                Authorization: token
            });
            return this.http.delete(Config.API_ALBUM + albumId, {headers});
        });
    }

    public authorizeDownload(password: string): Observable<any> {
        const form = new FormData();
        form.append('password', password);
        return this.http.post(Config.API_AUTHORIZE_DOWNLOAD, form);
    }

    public downloadBigImage(album: string, image: string) {
        const url = `${Config.API_IMAGE}${album}/${image}?disposition=download`;
        window.open(url, '_blank');
    }

    public downloadOriginalImage(album: string, image: string, token: string) {
        const url = `${Config.API_IMAGE}${album}/${image}?size=original&disposition=download&token=${token}`;
        window.open(url, '_blank');
    }
}
