import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Config} from '../../../../global/Config';
import {Location} from '@angular/common';

export interface DialogData {
    title: string;
    album: string;
    image: string;
}


@Component({
    selector: 'app-share-dialog',
    templateUrl: './share-dialog.component.html',
    styleUrls: ['./share-dialog.component.css']
})
export class ShareDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ShareDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.keyboardListener();
    }

    nativeShare = !!navigator.share;
    url = this.buildUrl();

    static buildPath(...urlFragments: string[]) {
        let url = '';
        for (const fragment of urlFragments) {
            url = Location.joinWithSlash(url, fragment);
        }
        return Location.stripTrailingSlash(url);
    }

    private static fallbackCopy() {
        const range = document.createRange();
        const selection = window.getSelection();
        const textField = document.getElementById('url-text');
        range.selectNode(textField);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
    }

    private buildUrl() {
        const shorten = this.data.album !== '';
        const archivePath = shorten ? 'f' : 'fotografije';
        return ShareDialogComponent.buildPath(Config.BASE_WEB_CONTENT_URL, archivePath, this.data.album, this.data.image);
    }

    close(): void {
        this.dialogRef.close();
    }

    keyboardListener() {
        window.onkeydown = (e) => {
            if (e.code === 'Escape') {
                e.preventDefault();
                this.close();
            }
        };
    }

    facebook() {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${this.url}&amp;src=sdkpreparse`;
        window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    twitter() {
        const url = `https://twitter.com/intent/tweet?text=${this.url}`;
        window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }

    native() {
        navigator.share({
            title: 'Deli povezavo',
            url: this.url
        }).then(() => {
            this.close();
        });
    }

    copy() {
        navigator.clipboard.writeText(this.url).then(() => {}, ShareDialogComponent.fallbackCopy);
    }
}
