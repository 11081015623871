<app-appbar></app-appbar>
<div class="wrapper">
  <h1 class="title mat-display-3">Pridruži se tabornikom</h1>
  <h1 class="mat-headline unselectable">Pridi pogledat</h1>
  <p class="mat-h3">
    Si radoveden/a, kaj taborniki počnemo in ali je to nekaj zate ali za vašega otroka? Pridruži se nam nekajkrat
    brezplačno! Pošlji nam sporočilo preko naše <a href="https://www.facebook.com/xisnoub" target="_blank">Facebook</a>
    strani ali <a
    routerLink="/kontakt">kontaktnega obraca</a> in z veseljem bomo uredili
    termin.
  </p>
  <h1 class="mat-headline unselectable">Vpis</h1>
  <p class="mat-h3">
    Si obiskal/a nekaj taborniških srečanj in ti je bilo <a
    href="https://drive.google.com/file/d/1CRLdu0wYLEoLgg0K485wNPXcSza5THHX/view?usp=sharing">všeč</a>? Potem je čas, da
    tudi uradno postaneš član našega
    društva. Najprej natisni in izpolni <a
    href="https://firebasestorage.googleapis.com/v0/b/enajsta.appspot.com/o/assets%2FPristopna%20izjava%20Obrazec%20(1).pdf?alt=media&token=37c33c16-6895-490f-8e78-1cfd089b56ce"
    target="_blank" rel="noopener noreferrer">pristopno
    izjavo</a>. Lahko jo izročiš vodniku na vodovem srečanju ali prineseš
    na naš <a href="https://goo.gl/maps/fd9kFz3j66Ws2cP36" target="_blank">naslov</a> v času uradnih ur. Člani društva
    plačujemo letno članarino v višini 50 evrov. Prosimo, da jo
    nakažeš na naš transakcijski račun SI56 6100 0000 7237 905 z namenom »ime in priimek člana«.
    <br/>
    Vpisne podatke bomo posredovali <a href="https://www.taborniki.si/" target="_blank" rel="noopener noreferrer">Zvezi
    Tabornikov
    Slovenije (ZTS)</a>, ki te bo vpisala v svojo člansko bazo. To
    prinaša številne ugodnosti, med drugim naročnino na mesečno revijo Tabor. Hkrati boš postali tudi član
    <a href="https://www.scout.org/" target="_blank" rel="noopener noreferrer">Svetovne Organizacije Skavtskega Gibanja
      (WOSM)</a>.
    <br/>
    Sedaj je tudi čas, da si priskrbiš <a routerLink="/uniforma">taborniški kroj in rodovo majico</a>. Rutice ti ni
    treba kupiti, saj si jo boš
    prislužil s rednim obiskovanjem vodovih srečanj in akcij.
</div>
<app-footer></app-footer>
