<div class="wrapper">
    <h1 class="title mat-display-3 unselectable">Dobrodelnost te nič ne stane!</h1>
    <h1 class="subtitle mat-headline unselectable">Rod XI. SNOUB se posveča mladim že vse od leta 1959. Skrivnost našega
        uspeha je tudi v podpori skupnosti. Že
        majhno dobro delo nariše velik nasmešek!</h1>

    <div class="button-wrapper">
        <div style="margin-right: 10px;">
            <button class="big-button float" routerLink="/prispevek" mat-flat-button color="accent">Prispevaj</button>
            <p class="caption">Nič te ne stane</p>
        </div>
        <button class="big-button no-animation" style="margin-left: 10px;" (click)="more()" mat-stroked-button>Preberi več</button>
    </div>
</div>
