import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {zoomIn, fade} from '../animations/animations';
import {AppbarService} from '../sections/main/services/appbar.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: []
})

export class AppComponent {
    onActivate() {
        window.scroll(0, 0);
    }
}
