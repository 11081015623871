<div id="image-viewer" class="wrapper" [ngClass]="backgroundClasses">
  <div class="toolbar" [ngClass]="toolbarClasses">
    <button mat-icon-button (click)="exit()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="right">
      <button mat-icon-button (click)="toggleFullscreen()" >
        <mat-icon>{{inFullscreen ? 'fullscreen_exit' : 'fullscreen'}}</mat-icon>
      </button>
      <button mat-icon-button (click)="openShareDialog()">
        <mat-icon>share</mat-icon>
      </button>
      <button mat-icon-button (click)="download()">
        <mat-icon>download</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
      <button mat-menu-item (click)="downloadOriginal()">Prenesi izvirnik</button>
    </mat-menu>
  </div>

  <div class="mobile-toolbar" [ngClass]="toolbarClasses">
    <div class="mobile-toolbar-item">
      <button mat-icon-button (click)="openShareDialog()">
        <mat-icon>share</mat-icon>
      </button>
      <p>Deli</p>
    </div>
    <div class="mobile-toolbar-item">
      <button mat-icon-button (click)="download()">
        <mat-icon>download</mat-icon>
      </button>
      <p>Shrani</p>
    </div>
    <div class="mobile-toolbar-item">
      <button mat-icon-button [matMenuTriggerFor]="mobileMenu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <p>Več</p>
    </div>
    <mat-menu #mobileMenu="matMenu" xPosition="before" yPosition="above">
      <button mat-menu-item (click)="downloadOriginal()">Prenesi izvirnik</button>
    </mat-menu>
  </div>

  <img [ngClass]="imageCLasses[0]" [ngStyle]="imageStyles[0]" class="image" alt="image">
  <img [ngClass]="imageCLasses[1]" [ngStyle]="imageStyles[1]" class="image" alt="image">

  <div *ngIf="navigationVisible" class="previous-nav-area" (click)="previous()">
    <div class="nav-previous nav">
      <svg width="36px" height="36px" class="v1262d JUQOtc yz5P3b" viewBox="0 0 24 24">
        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
      </svg>
    </div>
  </div>
  <div *ngIf="navigationVisible" class="next-nav-area" (click)="next()">
    <div class="nav-next nav">
      <svg width="36px" height="36px" class="v1262d JUQOtc yz5P3b" viewBox="0 0 24 24">
        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
      </svg>
    </div>
  </div>
</div>

<div *ngIf="!navigationVisible" class="hide-cursor"></div>
