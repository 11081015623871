<h1 mat-dialog-title>Naročilnica</h1>

<mat-dialog-content style="min-height: 300px; width: 400px; max-width: 100%;">
    <!-- <div *ngIf="!data.dialog">
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSffBBa4gkV_d23Z24eatU2Pgmv3V58BWbsD_O7RW7r8K7EnsA/viewform?embedded=true"
            width="640" height="750" frameborder="0" marginheight="0" marginwidth="0">Nalaganje …</iframe>
    </div>

    <div *ngIf="data.dialog">
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfZVKEBcizV9BfAyzxfWkXBGOIhy3Jkbg_N80rl84OvUrNWCg/viewform?embedded=true"
            width="640" height="750" frameborder="0" marginheight="0" marginwidth="0">Nalaganje …</iframe>
    </div> -->

    <h3 style="font-family: Consolas,monaco,monospace!important;" class="mat-headline">Počakajte trenutek (morda malo dlje) ...<br/>Rodovo kolekcijo še pripravljamo. Osveži stran čez 120653 minut in 12 sekund, okej 11 sekund. Ne, zdaj samo še 10 ;)</h3>



    <mat-spinner style=" margin: auto; margin-top: 40px;" mode="indeterminate"></mat-spinner>


</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close tabindex="-1">Zapri</button>
</mat-dialog-actions>