import {NgModule} from '@angular/core';
import {ArchiveAppbarComponent} from './photos/gallery/appbar/archive-appbar.component';
import {RouterModule, Routes} from '@angular/router';
import {PhotosComponent} from './photos/gallery/photos.component';
import {AlbumModule} from './photos/album/album.module';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {ArchiveManagementModule} from './archive-management/archive-management.module';
import {VarDirective} from './photos/gallery/appbar/var.directive';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ShareDialogComponent} from './photos/share-dialog/share-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {MatRippleModule} from '@angular/material/core';

const appRoutes: Routes = [
    {path: 'fotografije', component: PhotosComponent},
    {path: 'f', redirectTo: 'fotografije'}
];

@NgModule({
    declarations: [
        ArchiveAppbarComponent,
        PhotosComponent,
        VarDirective,
        ShareDialogComponent
    ],
    imports: [
        RouterModule.forRoot(appRoutes),
        BrowserModule,
        HttpClientModule,
        AlbumModule,
        CommonModule,
        MatIconModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        ArchiveManagementModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatDialogModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatDividerModule,
        MatRippleModule
    ],
    exports: [RouterModule]
})

export class ArchiveModule {
}
