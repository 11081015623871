import {Injectable} from '@angular/core';

@Injectable()
export class ApplicationStateService {

    readonly state: boolean;

    constructor() {
        const ua = navigator.userAgent;
        this.state = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
    }

}
