<app-appbar [editor]="editor" [albumObservable]="albumObservable"></app-appbar>
<div id="wrapper" class="wrapper">
  <div class="header" *ngIf="loaded && albumLoaded">
    <h1 id="main-title">{{album.getTitle()}}</h1>
    <p class="date">{{album.getPrettyDate()}}</p>
    <div class="description">
      <p>{{album.getDescription()}}</p>
    </div>
    <div class="location">
      <mat-icon>location_on</mat-icon>
      <h3>{{album.location}}</h3>
    </div>
  </div>
  <div id="content" class="content">
    <div *ngFor="let row of gridBuilder.grid.rows" class="row">
      <img
        (click)="navigateToImage(image.id, $event.target)"
        *ngFor="let image of row.images"
        [ngStyle]="{'height': row.getRowHeight(contentWidth, imageMargins) + 'px', 'margin': imageMargins + 'px'}"
        [id]="image.id"
        [src]="image.img.src"
        [alt]="image.id">
    </div>
  </div>
</div>

