/* tslint:disable:no-trailing-whitespace */
import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ArchiveManagementService} from '../archive-management.service';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {eventCategories} from '../../util/EventCategories';

@Component({
    selector: 'app-create-album',
    templateUrl: './new-album.component.html',
    styleUrls: ['./new-album.component.css']
})
export class NewAlbumComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private archiveManagementService: ArchiveManagementService) {
    }


    options: string[] = ['Maribor', 'Gornji Grad', 'Ljubljana', 'Internet', 'Pohorje'];
    filteredOptions: Observable<string[]>;

    // categories: Category[] = [];

    date = '';
    location = '';
    author = '';
    category = '';

    loading = false;

    locationFormControl = new UntypedFormControl();

    categories = eventCategories;

    ngOnInit() {
        this.filteredOptions = this.locationFormControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );

    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        if (value === '') {
            return [];
        }
        return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }

    createAlbum() {
        this.loading = true;
        // todo: validate
        this.archiveManagementService.createAlbum(
            '',
            '',
            this.date,
            this.location,
            this.author,
            this.category).then(promise => {
            promise.subscribe(response => {
                console.log(response);
                this.router.navigate([response.id], {relativeTo: this.route});
            });
        });
    }

    setDate(event: MatDatepickerInputEvent<Date>) {
        this.date = `${event.value.getFullYear()}-${event.value.getMonth() + 1}-${event.value.getDate()}`;
    }
}
