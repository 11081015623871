<div class="wrapper">

    <div mat-ripple class="card archive unselectable" onclick='window.location.href = "https://www.facebook.com/pg/xisnoub/photos/?tab=albums&ref=page_internal"'>
      <h1>Fotografije</h1>
      <p>Galerija fotografij akcij in dogodkov</p>
    </div>

    <div mat-ripple class="card koledar unselectable" routerLink="/koledar">
      <h1>Koledar</h1>
      <p>Koledar rodovih akcij in taborniških tekmovanj</p>
    </div>

    <div mat-ripple class="card vodi unselectable" routerLink="/vodi">
      <h1>Vodi</h1>
      <p>Seznam vodov, vodnikov in urnik vodovih sestankov</p>
    </div>

    <div mat-ripple class="card tabornistvo unselectable"
         onclick="window.open('https://www.taborniki.si/tabornistvo/').focus();">
      <h1>Taborništvo</h1>
      <p>Kdo smo in kaj počnemo</p>
    </div>


</div>
