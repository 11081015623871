import {Component, OnInit} from '@angular/core';
import {FileTransferService} from '../file-transfer.service';

@Component({
    selector: 'app-drop-files-surface',
    templateUrl: './drop-files-surface.component.html',
    styleUrls: ['./drop-files-surface.component.css']
})
export class DropFilesSurfaceComponent implements OnInit {

    constructor(
        private fileTransferService: FileTransferService
    ) {
    }

    dragInProgress = false;


    prepareListeners() {
        const dropZone = document.getElementById('dropzone');


        window.addEventListener('dragenter', (e) => {
            this.dragInProgress = true;
            this.allowDrag(e);
        });

        dropZone.addEventListener('dragover', this.allowDrag);

        dropZone.addEventListener('dragleave', (e) => {
            this.dragInProgress = false;
        });

        dropZone.addEventListener('drop', (e) => {
            this.dragInProgress = false;
            this.handleDrop(e);
        });
    }

    allowDrag(e) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    }

    handleDrop(e) {
        e.preventDefault();
        const files: File[] = e.dataTransfer.files;
        this.fileTransferService.pushAllToFileQueue(files);
    }

    ngOnInit(): void {
        this.prepareListeners();
    }

}
