<div class="background">
  <div class="wrapper">
    <h1 class="title mat-display-3 unselectable">Kdo smo?</h1>
    <h1 class="title quote mat-headline unselectable">“Mladim nudimo veščine za življenje!”</h1>
    <div class="container">
      <div class="cloud">
        <div routerLink="/kdo-smo" class="about elevate">
          <h1 class="vodstvo-t mat-headline unselectable">Kdo smo?</h1>
          <h3 class="vodstvo-t mat-subheadline unselectable">Društvo tabornikov rod XI. SNOUB Miloša Zidanška iz
            Maribora je taborniški rod z dolgo tradicijo.
            Aktivno sodelujemo z različnimi mladinskimi organizacijami v Mariboru. Štejemo približno 100
            aktivnih
            članov in smo eden pomembnejših taborniških rodov na tej strani Slovenije.</h3>
        </div>
        <div routerLink="/kontakt" class="vodstvo elevate">
          <h1 class="vodstvo-t mat-headline unselectable">Vodstvo rodu</h1>
          <h3 class="vodstvo-t mat-subheadline unselectable">Starešina <span>Nina Medved</span></h3>
          <h3 class="vodstvo-t mat-subheadline unselectable">Načelnik <span>Mitja Brglez</span></h3>
        </div>
      </div>
      <div class="list unselectable">
        <div class="item" onclick="window.location='/kdo-smo#zgodovina'">
          <mat-icon class="list-icon" aria-hidden="false">hourglass_full</mat-icon>
          <div>
            <h1 class="list-title mat-headline">Zgodovina</h1>
            <h3 class="list-body mat-subheading">Neprekinjeno delujemo že vse od decembra 1959, ko sta se v
              nov
              odred združili četa Vzhajajočega sonca in četa Dravskih biserov.</h3>
          </div>
        </div>
        <div class="item" onclick="window.location='/kdo-smo#cilji'">
          <mat-icon class="list-icon" aria-hidden="false">flag</mat-icon>
          <div>
            <h1 class="list-title mat-headline">Poslanstvo</h1>
            <h3 class="list-body maat-subheading ">Vzgajamo, izobražujemo, rekreiramo. Gradimo prijateljstva in
              vstopamo v odgovorno, avtonomno in angažirano odraslost.
            </h3>
          </div>
        </div>
        <div class="item" (click)='openMaps()'>
          <mat-icon class="list-icon" aria-hidden="false">place</mat-icon>
          <div>`
            <h1 class="list-title mat-headline">Lokacija</h1>
            <h3 class="list-body mat-subheading">V Mariboru imamo dve lokaciji; Mladinski taborniški center v
              Mestnem parku, kjer večinoma potekajo vodova srečanja in Klubske prostore na Verstovškovi, kjer
              sestankuje vodstvo.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
