<div class="header" id="header" [ngStyle]="backgroundImageStyle">
  <div class="content" id="content">
    <h1 class="white mat-display-3 title">XI. SNOUB Maribor</h1>
    <h2 class="mat-headline white subtitle">Društvo tabornikov rod XI. SNOUB Miloša Zidanška iz Maribora
    </h2>
    <div>
      <h3 class="white text-container mat-h3">Že odkar smo zapičili prvi klin leta 1959; izobražujemo, zabavamo in
        vzgajamo mlade. Potepamo se po naravi in gradimo prijateljstva, ki ostanejo. Že diši po dogodivščini? Kaj
        čakaš!</h3>
      <div class="buttons">
        <button routerLink="/pridruzi-se" class="big-button" style="margin-right: 20px;" mat-flat-button color="accent">
          Pridruži
          se
        </button>
        <button routerLink="/kdo-smo" class="big-button" mat-flat-button>Kdo smo?</button>
      </div>
    </div>
  </div>
</div>

<div class="mobile">
  <div class="mobile-header" [ngStyle]="mobileBackgroundImageStyle">
  </div>
  <div class="description">
    <h1>XI. SNOUB</h1>
    <p>Že odkar smo zapičili prvi klin leta 1959; izobražujemo, zabavamo in vzgajamo mlade. Potepamo se po naravi in
      gradimo prijateljstva, ki ostanejo. Že diši po dogodivščini? Kaj čakaš!</p>
    <button id="join-us" routerLink="/pridruzi-se" mat-flat-button color="accent">
      Pridruži se
    </button>
    <button id="about" routerLink="/kdo-smo" mat-flat-button>Kdo smo?</button>
  </div>

  <span class="line"></span>

</div>
