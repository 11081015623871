import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Mail } from './ContactViewModel';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {


	constructor(private recaptchaV3Service: ReCaptchaV3Service, private _snackBar: MatSnackBar) { }
	@Output() sent = new EventEmitter<boolean>();


	email = new UntypedFormControl('', [Validators.required, Validators.email]);
	name = new UntypedFormControl('', [Validators.required]);
	body = new UntypedFormControl('', [Validators.required]);


	mail = new Mail('', '', '');
	submitted = false;

	ngOnInit(): void {
	}

	getErrorMessage() {
		if (this.email.hasError('required')) {
			return 'Napišite e-naslov';
		}
		return this.email.hasError('email') ? 'Ni veljaven e-naslov' : '';
	}

	getErrorMessageBody() {
		if (this.body.hasError('required')) {
			return 'Napišite vprašanje';
		}
	}
	onSubmit() {

		if (!(this.email.valid && this.name.valid && this.body.valid)) {
			return;
		}

		this.recaptchaV3Service.execute('importantAction')
			.subscribe((token) => {
				this.submitted = true;

				const formData = new FormData();
				formData.append('from', this.mail.from);
				formData.append('subject', this.mail.subject);
				formData.append('message', this.mail.message);
				formData.append('g-recaptcha-response', token);

				const request = new XMLHttpRequest();
				request.open('POST', 'http://xisnoub.taborniki.si/mail.php');
				request.send(formData);
				request.onload = () => {
					if (request.status == 200) {
						(document.getElementById('ngForm') as HTMLFormElement).reset();
						const container = document.getElementById('activeForm');
						container.classList.remove('send');
						void container.offsetWidth;
						container.classList.add('send');
						this._snackBar.open('📬 Sporočilo je bilo poslano!', null, {
							duration: 4000,
						});
					}
					else {
						this._snackBar.open('📭 Sporočila ni bilo mogoče poslati.', null, {
							duration: 4000,
						});
					}
					this.submitted = false;
				};
			});
	}
}
