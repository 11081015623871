<app-appbar></app-appbar>

<div class="wrapper">

    <h1 class="title mat-display-3"><strong>Povezave</strong></h1>

    <h3 class="mat-h3">Na tej strani najdeš nekaj uporavnih povezav, ki ti lahko pomagajo pri pripravi programa.
        Predlogi so dobrodošli!
    </h3>

    <ul>
        <li>
            <h1 class="mat-headline">Animirani vozli <a
                    href="https://www.animatedknots.com/">https://www.animatedknots.com/</a>
            </h1>
        </li>
        <li>
            <h1 class="mat-headline">Taborniška Ropotarnica <a
                    href="https://www.taborniki.si/ropotarnica/">https://www.taborniki.si/ropotarnica/</a>
            </h1>
        </li>
        <li>
            <h1 class="mat-headline">Pesmarica <a href="">https://www.kaopesmarica.si</a></h1>
        </li>
        <li>
            <h1 class="mat-headline">Zakladnica taborniškega znanja <a
                    href="https://www.pinetreeweb.com/">https://www.pinetreeweb.com/</a>
            </h1>
        </li>
        <li>
            <h1 class="mat-headline">Arhiv revije Tabor: <a href="https://issuu.com/zts-tabor">https://issuu.com/zts-tabor</a>
            </h1>
        </li>
    </ul>
</div>
