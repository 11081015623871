import {Injectable} from '@angular/core';

import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Router} from '@angular/router';
import firebase from 'firebase/compat';
import User = firebase.User;

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isLoggedIn = false;
    redirectUrl: string;
    user: User;

    constructor(public afAuth: AngularFireAuth, public router: Router) {
        this.afAuth.authState.subscribe(user => {
            if (user) {
                this.user = user;
                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;
            }
        });
    }


    async login(username: string, password: string) {
        const result = await this.afAuth.signInWithEmailAndPassword(username, password);
        if (result) {
            this.isLoggedIn = true;
        }
        return result;
    }

    async logout() {
        await this.afAuth.signOut();
        this.isLoggedIn = false;
        return;
    }
}
