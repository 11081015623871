/* tslint:disable:no-trailing-whitespace max-line-length */
// noinspection JSMethodCanBeStatic

import {Component, OnInit} from '@angular/core';
import {Config} from '../../../../../../global/Config';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css', './mobile-header.component.css']
})
export class HeaderComponent implements OnInit {

    highResImageLoaded = false;

    backgroundImageStyle = {};
    mobileBackgroundImageStyle = {};

    ngOnInit(): void {
        this.loadHeaderImage();
        this.parallax();
    }

    private parallax() {

        const parallax = () => {
            if (window.innerWidth > 800) {
                this.parallaxRise(true);
            } else {
                this.parallaxRise(false);
            }
        };
        parallax();
        window.addEventListener('resize', parallax);

    }

    private parallaxRaiseListener() {
        const mobileIOS = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
        const scrollValue = window.pageYOffset;
        const image = document.getElementById('header');
        if (image) {
            image.style.transition = 'none';
            let value;
            if (!mobileIOS) {
                value = 'calc(50% + ' + (-scrollValue / 3) + 'px)';
            } else {
                value = 'calc(50% + ' + (scrollValue) + 'px)';
            }

            image.style.backgroundPositionY = value;
        }
    }

    private parallaxRise(enable: boolean) {
        if (enable) {
            window.addEventListener('scroll', this.parallaxRaiseListener);
        } else {
            window.removeEventListener('scroll', this.parallaxRaiseListener);
        }
    }

    private loadHeaderImage() {
        const url = '../../../../../../../assets/slike/JPEG/headers/tabor.jpg';
        const largeImage = new Image();
        largeImage.onload = () => {
            this.highResImageLoaded = true;
            this.backgroundImageStyle = {
                'background-image': `linear-gradient(rgba(0, 0, 0, 0.1), transparent), url(${url})`
            };
            this.mobileBackgroundImageStyle = {
                'background-image': `url(${url})`
            };
        };
        largeImage.src = url;
    }
}
