import {Component, OnInit} from '@angular/core';
import {ApplicationStateService} from '../../services/application-state-service.service';


@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.css']
})
export class AboutPageComponent implements OnInit {
    constructor(private state: ApplicationStateService) {
    }

    public mobile: boolean;

    url_webp = '../../assets/slike/webp/about/11.webp';
    url_jpg = '../../assets/slike/JPEG/about/11.jpg';
    current = 1;
    indicator = '01 / 05';

    ngOnInit(): void {
        this.mobile = this.state.state;
        const targets = document.querySelectorAll('.show-on-scroll');

        window.addEventListener('scroll', () => {
            targets.forEach((target) => {
                if (isInViewport(target)) {
                    target.classList.add('is-visible');
                }
            });
        });

        const isInViewport = (elem) => {
            const bounding = elem.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
                bounding.left >= 0 &&
                bounding.bottom <= (window.innerHeight * 1.2 || document.documentElement.clientHeight) &&
                bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };
    }

    slideshow(direction: boolean) {
        const urlTemplatejpg = '../../assets/slike/JPEG/about/';
        const urlTemplatewebp = '../../assets/slike/webp/about/';
        if (direction) {
            this.current++;
            if (this.current > 5) {
                this.current = 1;
            }
            this.indicator = '0' + this.current + ' / 05';
        } else {
            this.current--;
            if (this.current < 1) {
                this.current = 5;
            }
            this.indicator = '0' + this.current + ' / 05';
        }
        this.url_webp = urlTemplatewebp + (this.current + 10) + '.webp';
        this.url_jpg = urlTemplatewebp + (this.current + 10) + '.jpg';
    }
}
