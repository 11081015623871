<app-appbar></app-appbar>

<div class="header">
  <h1 class="title mat-display-3">Rod XI. SNOUB</h1>

  <h1 class=" subtitle mat-headline">Društvo tabornikov rod XI. SNOUB Miloša Zidanška iz Maribora.
    Neprekinjeno delujemo že vse od leta 1959. Smo eden pomembnejših rodov v vzhodni Sloveniji.</h1>
</div>

<div class="banner inline-photo is-visible">
  <img src="../../assets/images/about/1.webp" alt=""/>

  <picture class="imageholder-1">
    <source srcset="../../../../../assets/slike/webp/about/1.webp" type="image/webp">
    <img class="image-1" style="border-bottom-left-radius: 4px; border-top-left-radius: 4px;"
         src="../../../../../assets/slike/JPEG/about/1.jpg">
  </picture>


  <div class="image-container">

    <picture>
      <source srcset="../../../../../assets/slike/webp/about/2.webp" type="image/webp">
      <img class="image-2" style="border-top-right-radius: 4px;" src="../../../../../assets/slike/JPEG/about/2.jpg">
    </picture>

    <picture>
      <source srcset="../../../../../assets/slike/webp/about/3.webp" type="image/webp">
      <img class="image-2" style="border-bottom-right-radius: 4px;" src="../../../../../assets/slike/JPEG/about/3.jpg">
    </picture>
  </div>
</div>

<div class="wrapper">

  <div class="description">

    <div class="text">
      <h1 class="headline">Kaj počnemo?</h1>

      <p id="text">
        Naš namen je prispevati k osebnemu razvoju mladih in jim pomagati postati
        avtonomni
        posamezniki, ki bodo kot odgovorni ter solidarni člani naše družbe prispevali k celotni skupnosti. To
        dosegamo s pomočjo taborniškega pristopa, sistema stopnjevanega samoizobraževanja, ki vključuje delo v
        majhnih skupinah, izobraževanje na raznolikih področjih, skrb za posameznikov razvoj in skrb za naravo.
        Vse
        to je vpeto v simbolen okvir, ki pomensko preveva vso našo dejavnost. Naš program ustvarjajo in izvajajo
        mladi za mlade!
      </p>

      <div  class="link unselectable" onclick="window.location.href='https://www.taborniki.si/tabornistvo/'" id="link">
        <p>Preberi več o taborništvu</p>
        <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_forward</mat-icon>
      </div>


    </div>

    <div class="picture">
      <picture>
        <source [srcset]="url_webp" type="image/webp">
        <img id="image1" [src]="url_jpg">
      </picture>


      <div class="slideshow-buttons">
        <button (click)="slideshow(false)" mat-icon-button>
          <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_back</mat-icon>
        </button>
        <p [innerHTML]="indicator"></p>
        <button (click)="slideshow(true)" mat-icon-button>
          <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_forward</mat-icon>
        </button>
      </div>
    </div>


  </div>

  <div class="timeline inline-photo show-on-scroll" id="zgodovina">
    <h1 class="center headline">Zgodovina rodu</h1>

    <picture>
      <source srcset="../../../../../assets/slike/webp/about/9.webp" type="image/webp">
      <img src="../../../../../assets/slike/JPEG/about/9.jpg">
    </picture>


    <p class="center "><br/>Prispevek bo kmalu na voljo.</p>
  </div>

  <div class="taborni-prostor">


    <picture>
      <img src="../../../../../assets/slike/JPEG/headers/tabor-about.jpg">
    </picture>

    <div class="card inline-photo show-on-scroll">

      <h1 class="headline">Taborni prostor</h1>

      <p id="text">Naš rod ima taborni prostor v bližini naselja Gornji Grad, v Zgornji
        Savinjski dolini.
        Nahaja se na pobočju Menine planine in le kakšnih 15 minut hoje stran od centra Gornjega Grada, vendar
        je zaradi gozda, ki ga obdaja, primerno skrit pred človeškim vrvežem.</p>

      <div routerLink="/taborni-prostor" id="link" class="link unselectable">
        <p>Več informacij o tabornem
          prostoru</p>
        <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_forward</mat-icon>
      </div>
    </div>

  </div>

  <div class="zot">
    <h1 class="center headline">ZOT - Zimsko Orientacijsko Tekmovanje</h1>

    <div class="zot-top">


      <p id="text" class="center">Eden večjih dogodkov, ki jih organiziramo za mlade, je Zimsko
        orientacijsko
        tekmovanje
        (ZOT), ki poteka zadnji vikend v januarju. Tekmovanja se vsako leto udeleži okoli 250 tabornikov iz vse
        Slovenije in šteje med najpomembnejša tovrstna tekmovanja pri nas.</p>

      <div onclick="window.location.href='http://zot.taborniki.si/'" id="link" class="link unselectable center">
        <p>Več informacij o tekmovanju</p>
        <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_forward</mat-icon>
      </div>

    </div>

    <iframe width="640" height="360" src="https://www.youtube.com/embed/yl1_tXDDf6E" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
            class="video inline-photo show-on-scroll"></iframe>
  </div>
</div>

<app-footer></app-footer>
